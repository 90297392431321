import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import Card from '../../components/Card/Card';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import TemplateFrom from './TemplateFrom';
import { getTemplateById, createTemplate, updateTemplate } from '../../services/TemplateService';

const TemplateManage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const programData = useSelector((state) => state.program.programs.data ?? []);
  const [id, setId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [term, setTerm] = useState('');
  const [template_path, setTemplatePath] = useState('');
  const [program_ids, setProgramIds] = useState([]);
  const [publish_date, setPublishDate] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (params.id) {
      getUniqueTemplate();
    }
    dispatch(fetchPrograms());
  }, [params.id, dispatch]);

  async function getUniqueTemplate() {
    setLoader(true);
    try {
      const response = await getTemplateById(params.id);
      if (response.status === 200) {
        const { id, term, template_path, type, publish_date } = response.data.data;
        const program = response.data.data.programs;
        const programIds = program.map((item) => item.id.toString());

        setId(id);
        setTerm(term);
        setTemplatePath(template_path);
        setType(type);
        setPublishDate(publish_date);
        setProgramIds(programIds);
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(true);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'term') {
      setTerm(value);
    } else if (name === 'template_path') {
      setTemplatePath(value);
    }
    else if (name === 'type') {
      setType(value);
    }
    else if (name === 'publish_date') {
      setPublishDate(value);
    }
  };

  const handleInputChange = (event) => {
    const prog_id = event.target.value;
    const value = event.target.value;
    let updatedProgramIds;

    if (event.target.checked) {
      updatedProgramIds = [...program_ids, prog_id];
    } else {
      updatedProgramIds = program_ids.filter((id) => id !== value);
    }
    setProgramIds(updatedProgramIds);
  };

  const handleSwitchChange = (event) => {
    let updatedProgramIds;
    if (event.target.checked) {
      updatedProgramIds = programData.map((program) => program.id.toString());
    } else {
      updatedProgramIds = [];
    }
    setProgramIds(updatedProgramIds);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(false);
    let organization_id = localStorage.getItem('organization_id');
    const session_id = localStorage.getItem('session_id');
    const data = {
      organization_id,
      session_id,
      term,
      template_path,
      type,
      publish_date,
      program_ids,
    };

    try {
      const response = id
        ? await updateTemplate(id, data)
        : await createTemplate(data);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        navigate('/template/list');
      }
    } catch (error) {
      console.error(error);
      setLoader(true);
    }
  };

  const back = () => {
    navigate('/template/list');
  };

  return (
    <Card componentName={id ? 'Update Template' : 'Add Template'} back={back}>
      {loader ? <Loader /> :
        <TemplateFrom
          term={term}
          template_path={template_path}
          type={type}
          publish_date={publish_date}
          program_ids={program_ids}
          programData={programData}
          handleInputChange={handleInputChange}
          handleSwitchChange={handleSwitchChange}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      }
    </Card>
  );
};

export default TemplateManage;
