import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import { termOptions } from '../../globalConstants';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';

const AssessmentListForm = ({ handleSubmit, handleChange, handleSwitchChange, handleInputChange,
  state, programData }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-4">
          <Input
            label={"Name"}
            type="text"
            name="name"
            id="name"
            onChangeHandler={handleChange}
            placeholder="Enter Name"
            value={state.name}
            required={true}
          />
        </div>
        <div className="col-md-4">
          <Select
            label={"Term"}
            name="term"
            value={state.term}
            onChangeHandler={handleChange}
            options={termOptions}
            required={true}
            placeHolder="Select Term"
          />
        </div>
        <div className="col-md-4">
          <Input
            label={"Position"}
            name="position"
            value={state.position}
            onChangeHandler={handleChange}
            placeholder="Enter Position"
            required={true}
          />
        </div>
        <div className="col-md-12 mt-3">
          <Form.Label>Available for classes</Form.Label><br />
          <Switch
            checked={state.program_ids && (state.program_ids.length > 0) &&
              (state.program_ids.length === programData.length)}
            value="1"
            name="all"
            onChange={handleSwitchChange}
            id="select-all"
            label={'Select All'}
          />

          <div className="row">
            {programData && programData.map((program, idx) => (
              <div className="col-md-3" key={idx}>
                <Switch
                  name="program_ids"
                  id={program.id}
                  checked={state.program_ids && (state.program_ids.includes(program.id.toString()))}
                  value={program.id}
                  onChange={handleInputChange}
                  label={program.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-1">
          <Button variant="success"
            disabled={state.loading}
            type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default AssessmentListForm;
