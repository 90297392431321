import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import { Form, Button } from 'react-bootstrap';
import { Multiselect } from "multiselect-react-dropdown";
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';
import Select from '../../components/Select/Select';

const StudentSubjectMappingForm = props => {
  const { fetchPrograms, programData } = props;

  useEffect(() => {
    fetchPrograms();
  }, []);

  return (
    <Form onSubmit={props.submitHandler} onChange={props.changeHandler}>
      <div className="row">
        <div className="col-md-6">
          <Select
            name="program_id"
            label="Class"
            value={props.program_id || ''}
            options={programData}
            onChange={props.changeHandler}
            placeholder="Select Class"
          />
        </div>
      </div>
      <div className="row mt-3">
        {/*props.subjectData &&
          <Switch
            name="select_all"
            id={"select_all"}
            checked={props.subject_ids.length === props.subjectData.length ? true : false}
            onChange={props.allSubjectChangeHandler}
            label={"Select All"}
          />*/}
        {props.subjectData && props.subjectData.map((subject, index) =>
          <div className="col-md-4 mt-2" key={index}>
            <Switch
              name="subject_id"
              id={"subject"+subject.id}
              value={subject.id}
              checked={props.subject_ids.toString().includes(subject.id)}
              onChange={props.subjectChangeHandler}
              label={subject.name}
            />
          </div>
        )}
      </div>

      {/* TODO all records fetch from API and when update checkbox field already selected */}
      <div className="row mt-2">
        <div className="col-md-12">
          {props.student_loader ? <Loader /> :
            <Form.Group>
              <table className="table table-hover table-bordered table-striped mt-2">
                <thead>
                  <tr>
                    <th>SrNo</th>
                    <th>Roll No</th>
                    <th>Admission No</th>
                    <th>Name</th>
                    <th>
                      <Switch
                        checked={(props.student_ids.length ===
                          (props.studentList ? props.studentList.length : null))
                          && props.studentList.length > 0 ? true : false}
                        name="all"
                        id="select-all"
                        value="1"
                        onChange={e => props.selectAllStudent(e)}
                        label={'Select All'}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody className="col-md-12">
                  {
                    props.studentList && props.studentList.map((student, index) =>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{student.roll_no}</td>
                        <td>{student.id_number}</td>
                        <td>{student.first_name + " " + student.last_name}</td>
                        <td>
                          <div className="form-check form-check-inline">
                            {props.student_ids.length &&
                              props.student_ids.indexOf(student.id.toString()) !== -1 ?
                              <Switch
                                name="student_id"
                                id={student.id}
                                checked={props.student_ids.indexOf(student.id.toString()) !== -1 ? true : false}
                                value={student.id || ''}
                                onChange={props.studentChangeHandler}
                              /> :
                              <Switch
                                name="student_id"
                                id={student.id}
                                value={student.id || ''}
                                onChange={props.studentChangeHandler}
                              />
                            }
                          </div>
                        </td>
                      </tr>)}
                </tbody>
              </table>
            </Form.Group>}
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end">
        <Button variant="success" className='mt-2'
          type="submit">Submit</Button>
      </div>
    </Form>
  );
};
const mapdataToprops = data => {
  return {
    programData: data.program.programs.data,
  }
}
const mapDispatchToprops = dispatch => {
  return {
    fetchPrograms: () => dispatch(fetchPrograms()),
  }
}

export default connect(mapdataToprops, mapDispatchToprops)(StudentSubjectMappingForm);

