import React from 'react';

const Select = ({ label, name, value, required = false, className, placeHolder, options, onChangeHandler }) => {
  return (
    <React.Fragment>
      {label && <label htmlFor={name} className='mb-1 mt-2'>{label}{required && <i className="text-danger">*</i>}</label>}
      <select
        onChange={onChangeHandler}
        value={value}
        name={name}
        id={name}
        className={`cursor-pointer form-select ${className}`}
        data-placeholder={placeHolder}
      >
        {placeHolder && <option value={''}>{placeHolder}</option>}
        {options && options.map((item, idx) => (
          <option value={item.id} key={idx}>
            {item.name}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default Select;
