import React from "react";

export const tableConstants = () => {
  var count = 1;
  let formatedData = [
    {
      title: 'SrNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Subject Name',
      render: rowData => {
        return <span>{`${rowData.subject.name}`}</span>;
      },
    },
    {
      title: 'Mapped Students',
      render: (rowData) => {
        return <span>{rowData.student_count}</span>;
      },
    },
    {
      title: 'Total Students',
      render: rowData => {
        return <span>{rowData.total_student}</span>;
      },
    }
  ];
  return formatedData;
};
