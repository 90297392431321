import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchOrganizationId } from "../../redux/organizationID/organizationIDActions";
import { fetchSessions } from "../../redux/session/sessionActions";
import { getUserOrganizations } from "../../services/OrganizationService";
import { enqueueSnackbar } from "notistack";
import { getPermissionsByUser } from "../../services/RolePermissionService";
import Select from "../../components/Select/Select";

const Organization = () => {
  const [selectedOrg, setSelectedOrg] = useState(localStorage.getItem("organization_id") || "");
  const [organizationData, setOrganizationData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganizationId(selectedOrg || "1"));
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    try {
      const response = await getUserOrganizations();
      if (response.status === 200) {
        const organizationData = response.data.data;
        localStorage.setItem("organizationData", JSON.stringify(organizationData));
        setOrganizationData(organizationData);
      } else {
        console.error("Request was not successful:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await getPermissionsByUser();
      if (response.status === 200) {
        localStorage.setItem("permissions", JSON.stringify(response.data.data));
      } else {
        console.error("Request was not successful:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const orgID = event.target.value;
    localStorage.setItem("organization_id", orgID);
    localStorage.removeItem("session_id");
    axios.defaults.headers.common = { "organization-id": orgID };
    dispatch(fetchSessions());
    setSelectedOrg(orgID);
    enqueueSnackbar("Please select session", { variant: "error" });
    fetchPermissions();

    if (orgID) {
      // dispatch(fetchOrganizationId(orgID));
      // dispatch(fetchPrograms({ selected: 0 }));
      // dispatch(fetchSubjects({ selected: 0 }));
      // dispatch(fetchTraits());
      // dispatch(fetchTeachers("", 1));
      // dispatch(fetchScoreSchemes({ selected: 0 }));
      // dispatch(fetchScoreSchemesCategory());
      // dispatch(fetchEvaluationTypes());
      // dispatch(fetchAccessOrganizations());
    } else {
      enqueueSnackbar("Please select school", { variant: "error" });
    }
  };

  return (
    <Select
      name="organization_id"
      value={selectedOrg}
      className="form-select"
      // placeHolder="Select Organization"
      onChangeHandler={handleChange}
      options={organizationData}
    />
  );
};

export default Organization;
