import React from 'react';
import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import Switch from '../../../components/Switch/Switch';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';

const CircularForm = ({ changeHandler, submitHandler, fileUploadHandler,loading,
  handleInputChange, handleSwitchChange, circular_desc, circular_topic, circular_no,
  is_ews, circular_file, id, programData, program_ids }) => {

  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-6">
          <Input
            label="Circular Topic"
            name="circular_topic"
            type="text"
            placeholder={'Enter Circular Topic'}
            value={circular_topic}
            onChangeHandler={(e) => changeHandler(e)}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Circular No"
            name="circular_no"
            type="text"
            placeholder={'Enter Circular No'}
            value={circular_no}
            disabled={id}
            onChangeHandler={(e) => changeHandler(e)}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Circular File"
            name="circular_file"
            type="file"
            onChangeHandler={(e) => fileUploadHandler(e)}
          />
          {id && <Link to={circular_file} target="_blank">View File</Link>}
        </div>
        <div className="col-md-6">
          <Select
            label={'Only for Ews students'}
            name="is_ews"
            value={is_ews}
            placeHolder={'Choose Ews'}
            options={[
              { id: 1, name: 'Yes' },
              { id: 0, name: 'No' },
            ]}
            onChangeHandler={(e) => changeHandler(e)}
          />
        </div>
        <div className="col-md-12 mt-2">
          <Form.Group>
            <Form.Label>Description</Form.Label>
          </Form.Group>
          <CKEditor
            editor={ClassicEditor}
            data={circular_desc}
            onChange={(event, editor) => {
              const data = editor.getData();
              changeHandler({ target: { name: 'circular_desc', value: data } });
            }}
          />
        </div>
        <div className="col-md-12">
          <Form.Label>Available for classes</Form.Label>
          <Switch
            label='Select All'
            value={'1'}
            checked={program_ids.length === programData.length}
            name='all'
            id='select-all'
            onChange={handleSwitchChange}
          />
          <div className="row">
            <div className="col-md-12">
              {programData &&
                programData.map((program, idx) => (
                  <div className="form-check form-check-inline col-md-2" key={idx}>
                    <Switch
                      label={program.name}
                      id={program.id}
                      name="program_ids"
                      value={program.id}
                      checked={program_ids.includes(program.id.toString())}
                      onChange={handleInputChange}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-end mt-3">
          <div className="col-md-1">
            <Button variant="primary"
            disabled={loading}
            type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>);
};
export default CircularForm;
