import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getTraits = async () => {
  try {
    const response = await axios.get(ENDPOINTS.TRAIT.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getTraitById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.TRAIT.GET_TRAIT}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createTrait = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.TRAIT.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateTrait = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.TRAIT.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTrait = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.TRAIT.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
