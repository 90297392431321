import React from 'react';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';

const AttendanceSubmissionAlert = ({
  isModalOpen,
  setIsModalOpen,
  studentAttendance,
  present,
  absent,
  leave,
  confirmHandler
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Total Strength</th>
            <th>{studentAttendance.length}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Present</td>
            <td>{present}</td>
          </tr>
          <tr>
            <td>Absent</td>
            <td>{absent}</td>
          </tr>
          <tr>
            <td>Leave</td>
            <td>{leave}</td>
          </tr>
        </tbody>
      </table>
      <Button variant="success" onClick={confirmHandler}>Confirm</Button>
    </Modal>
  );
};

export default AttendanceSubmissionAlert;
