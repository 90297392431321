import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import { termOptions } from '../../globalConstants';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';

const TemplateFrom = (
  {
    handleSubmit, handleChange, term, template_path,
    type, publish_date, program_ids, programData,
    handleSwitchChange, handleInputChange,
  }
) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Select
            label={'Term'}
            name="term"
            value={term}
            onChangeHandler={handleChange}
            options={termOptions}
            placeHolder="Select Term"
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Path"
            type="text"
            name="template_path"
            onChange={handleChange}
            placeholder="Enter path"
            value={template_path}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Select
            label={'Type'}
            name="type"
            value={type}
            onChangeHandler={handleChange}
            options={[
              { id: 0, name: 'Template' },
              { id: 1, name: 'Excel' }]
            }
            placeHolder="Select Type"
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Publish Date"
            type="date"
            name="publish_date"
            onChangeHandler={handleChange}
            placeholder="Enter Publish Date"
            value={publish_date}
            required={true}
          />
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Label>Available for classes</Form.Label>
            <br />
            <Switch
              checked={program_ids.length === programData.length}
              value="1"
              name="all"
              onChange={handleSwitchChange}
              id="select-all"
              label={'Select All'}
            />
          </div>
          <div className="col-md-12">
            {programData && programData.map((program, idx) => (
              <div className="form-check form-check-inline col-md-2" key={idx}>
                <Switch
                  name={`program_ids${idx}`}
                  id={program.id}
                  checked={program_ids.includes(program.id.toString())}
                  value={program.id}
                  onChange={handleInputChange}
                  label={program.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-1">
          <Button variant="success" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TemplateFrom;
