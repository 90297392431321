import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Multiselect } from 'multiselect-react-dropdown';

const UserSubjectClassMapping = ({ handleChange, subjectChangeHandler, onSubjectRemove,
  addNewRow, clickOnDelete, state
}) => {
  return (
    <div className="col-md-12 px-0">
      <div className="row">
        <div className="col-md-4">
          <label>Class</label>
        </div>
        <div className="col-md-4">
          <label>Subject</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {state.programs.map((val, idx) => {
            return (
              <div className="row mb-2" key={idx}>
                <div className="col-md-4">
                  <select
                    name="id"
                    data-id={idx}
                    className="form-select form-control cursor-pointer"
                    value={val.id}
                    onChange={(e) => handleChange(e, idx)}
                    placeholder="Select class"
                  >
                    <option value="all">Choose Class</option>
                    {state.programData && state.programData.map((program) => (
                      <option value={program.id} key={program.id}>
                        {program.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <Multiselect
                    name="subjects"
                    options={val.subject_list ? val.subject_list : state.subjectData}
                    data-id={idx}
                    id={val && val.subjects ? val.subjects : []}
                    selectedValues={val && val.subjects ? val.subjects : []}
                    onRemove={(e) =>
                      onSubjectRemove(e, val.id)
                    }
                    displayValue="name"
                    showCheckbox={true}
                    onSelect={(e) =>
                      subjectChangeHandler(e, idx)
                    }
                  />
                </div>
                <div className="col-md-4">
                  {idx === 0 ? (
                    <button
                      onClick={addNewRow}
                      type="button"
                      className="btn btn-primary text-center"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger"
                      onClick={() => clickOnDelete(val)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UserSubjectClassMapping;
