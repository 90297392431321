import React from 'react';
import { Form } from 'react-bootstrap';

const TraitMarking = ({ traits, student, eventInfo, traitChangeHandler, editableStudentId, studentIndex }) => {
  let traitHash = {};
  student.marks_data.map(item => traitHash[item.trait_id] = item);
  return (
    <React.Fragment>
      {traits && traits.map((studentTrait, traitIndex) =>
        <React.Fragment key={traitIndex}>
        {(traitHash[studentTrait.subject_trait.id]) ?
          <React.Fragment>
          {editableStudentId === student.id ?
            <td>
            {console.log(traitHash[studentTrait.subject_trait.id],'studentTrait', studentTrait,studentTrait.grade_scheme.options)}
                  {(eventInfo.grade_scheme.type === 'Letters' || eventInfo.grade_scheme.type === 'Text') ?
                    <select onChange={event => traitChangeHandler(event, student.id,
                      studentTrait.subject_trait.id, studentIndex)}
                      value={traitHash[studentTrait.subject_trait.id] ? traitHash[studentTrait.subject_trait.id].option_id : ''}
                      // value={student.marks_data[traitIndex]?(student.marks_data[traitIndex].option_id): 0}
                      className="form-select form-control cursor-pointer"
                      name="option_id"
                      style={{ width: 150 }}
                      data-placeholder="Select organization">
                      <option value={''}>Options</option>
                      {studentTrait.grade_scheme.options.map((Option, idx) =>
                        <option value={Option.id} key={idx}>
                          {Option.option_name}</option>
                      )}
                    </select> :
                    <Form.Control type="number" name="option_value"
                      onChange={event => traitChangeHandler(event, student.id, studentTrait.subject_trait.id, studentIndex)} />
                  }</td> :
                <td>{student.marks_data &&
                  student.marks_data.map(item => item.trait_id === studentTrait.subject_trait.id ? item.option_value : '')}</td>}
            </React.Fragment> :
            <React.Fragment>
              <td>
                {(eventInfo.grade_scheme.type === 'Letters' || eventInfo.grade_scheme.type === 'Text') ?
                  <select onChange={event => traitChangeHandler(event, student.id, studentTrait.subject_trait.id, studentIndex)}
                    className="form-select form-control cursor-pointer"
                    name="option_id"
                    style={{ width: 150 }}
                    data-placeholder="Select organization">
                    <option value={0}>Options</option>
                    {studentTrait.grade_scheme && studentTrait.grade_scheme.options.map((Option, idx) =>
                      <option value={Option.id} key={idx}>
                        {Option.option_name}</option>
                    )}
                  </select>
                  :
                  <Form.Control type="text" name="option_value"
                    onChange={event => traitChangeHandler(event, student.id, studentTrait.subject_trait.id, studentIndex)} />
                }
              </td>
            </React.Fragment>
          }
        </React.Fragment>)}
    </React.Fragment>
  );
};

export default TraitMarking;
