import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

function MarkingResultView({ resultData, remarkType, pdfData }) {
  // If data is not present or not an object, return null
  if (!resultData || !resultData.result_data || typeof resultData.result_data !== 'object') {
    return null;
  }
  let data = resultData;
  let count = 1;
  let dataEntries = [];
  // Extracting keys and values from data for later rendering
  if (remarkType === 'REMARKS') {
    dataEntries = Object.values(data.result_data);
  }
  else {
    dataEntries = Object.values(data.result_data);
  }

  console.log('dataEntries', dataEntries);
  return (
    <div className=''>
      <div className='card-header'>
        <h4>
          {resultData.student_profile.stu_first_name + ' ' + resultData.student_profile.stu_last_name}
        </h4>
      </div>
      <div className='card-body'>
        {remarkType === 'REMARKS' ?
          <React.Fragment>
            {pdfData ?
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfData} />
              </Worker> : null}
          </React.Fragment>
          :
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Subject</th>
                <th>Exam Name</th>
                <th>Obtained Marks</th>
                <th>Max Score</th>
              </tr>
            </thead>
            <tbody>
              {dataEntries.length === 0 ? (
                <tr align="center">
                  <td colSpan="4">No Record Found</td>
                </tr>
              ) : (
                dataEntries.map((value) => (
                  //Render your table rows based on data
                  value && value.map((item, idx) => (
                    item.event.map((trait, index) => (
                      <tr key={index}>
                        <td>{count++}</td>
                        <td>{item.subject_name}</td>
                        <td>{trait.event_name}</td>
                        <td>{trait.option_value}</td>
                        <td>{trait.event_max_score ? trait.event_max_score : '-'}</td>
                      </tr>))))
                ))
              )}
            </tbody>
          </table>}
      </div>
    </div>
  );
}

export default MarkingResultView;
