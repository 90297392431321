import React from "react";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (handleDelete, permissions) => {
  let count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Path',
      render: rowData => {
        return <span>{rowData.template_path}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>{rowData.term}</span>;
      },
    },
    {
      title: 'Type',
      render: rowData => {
        return <span>{rowData.type === 0 ? "Template" : "Excel"}</span>;
      },
    },
    {
      title: 'Publish_Date',
      render: rowData => {
        return <span>{rowData.publish_date}</span>;
      },
    },
    {
      title: 'Classes',
      render: rowData => {
        return <React.Fragment>{rowData.programs && rowData.programs.map((item, idx) => <span key={idx}>{item.name}, </span>)}</React.Fragment>;
      },
    },
  ];

  if (permissions.includes("template.update") || permissions.includes("template.delete")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("template.update") && (
              <Link
                to={`/template/update/${rowData.id}`}
                className="mr-2"
              >
                <FontAwesomeIcon icon={faEdit} color="blue" />
              </Link>
            )}
            &nbsp;&nbsp;
            {permissions.includes("template.delete") && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you wish to delete this item?"
                    )
                  )
                    handleDelete(rowData.id);
                }}
              >
                <FontAwesomeIcon icon={faTrash} color="red" />
              </span>
            )}
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
