import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { enqueueSnackbar } from "notistack";
import { createRolePermission, getPermissions, getRolePermissionById, updateRolePermission } from "../../services/RolePermissionService";
import RolePermissionForm from "./RolePermissionForm";

const RolePermission = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "", isLoader: false,
    permissionData: [], permissions: [],
    id: params.id ? params.id : "",
  });

  useEffect(() => {
    getAllPermissions();
    if (formData.id) getRoleWithPermissions(formData.id);
  }, [formData.id]);

  const getAllPermissions = async () => {
    setFormData((prevData) => ({ ...prevData, isLoader: true }));
    try {
      const response = await getPermissions(1);
      if (response.status === 200) {
        const data = response.data.data.sort();
        setFormData((prevData) => ({ ...prevData, permissionData: data, isLoader: false }));
      }
    } catch (error) {
      setFormData((prevData) => ({ ...prevData, isLoader: false }));
      console.error("Error fetching data:", error);
    }
  };

  const getRoleWithPermissions = async (roleId) => {
    setFormData((prevData) => ({ ...prevData, isLoader: true }));
    try {
      const response = await getRolePermissionById(roleId);
      console.log("response", response);
      if (response.status === 200) {
        const perms = response.data.permissions;
        const roleName = response.data.role.name;
        setFormData((prevData) => ({
          ...prevData,
          permissions: perms,
          name: roleName,
          isLoader: false,
        }));
      }
    } catch (error) {
      setFormData((prevData) => ({ ...prevData, isLoader: false }));
      console.error("Error fetching data:", error);
    }
  };

  const selectAllPermissionHandler = (event) => {
    if (event.target.checked) {
      setFormData((prevData) => ({
        ...prevData,
        permissions: [...prevData.permissionData],
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, permissions: [] }));
    }
  };

  const permissionHandler = (event, permName) => {
    if (event.target.checked) {
      setFormData((prevData) => ({
        ...prevData,
        permissions: [...prevData.permissions, permName],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        permissions: prevData.permissions.filter((action) => action !== permName),
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData((prevData) => ({ ...prevData, isLoader: true }));

    const { name, permissions } = formData;
    const organizationId = localStorage.getItem("organization_id");
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user.id;

    const data = { name, organization_id: organizationId, user_id: userId, permissions };

    try {
      let response;
      if (formData.id) {
        data.id = formData.id;
        response = await updateRolePermission(formData.id, data);
      } else {
        response = await createRolePermission(data);
      }
      if (response.status === 200) {
        enqueueSnackbar("Success message", { variant: "success" });
        navigate('/role/list');
      }
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setFormData((prevData) => ({ ...prevData, isLoader: false }));
    }
  };

  const back = () => {
    navigate("/role/list");
  };

  return (
    <Card back={back}
      componentName={`Manage Role Permission`}>
      {formData.isLoader ?
        <Loader /> :
        <RolePermissionForm
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          selectAllPermissionHandler={selectAllPermissionHandler}
          permissionHandler={permissionHandler}
          formData={formData}
        />}
    </Card>
  );
};

export default RolePermission;
