import React from "react";
import Switch from "../../components/Switch/Switch";

export function StudentRow({ student, index, handleInputChange, student_idnumbers }) {

  return (
      <tr key={index}>
        <th>{student.roll_no}</th>
        <td>{student.id_number}</td>
        <td>{`${student.first_name} ${student.last_name}`}
        </td>
        <td >
          <Switch
            name="program_ids"
            id={student.id}
            checked={student_idnumbers.includes(student.id_number.toString())}
            value={student.id_number}
            onChange={handleInputChange}
            label={student.name}
          />
        </td>
      </tr>
  );
}
