import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import AssessmentFilter from "../AssessmentMarking/AssessmentFilter";
import { tableConstants } from "./ExamConfigTableConstant";
import Table from "../../components/Table/Table";
import '../Exam/Exam.css';
import {
  getProgramWiseSubjectList, getProgramWiseExamList,
  examActiveInActive
} from "../../services/ExamService";
import { getExamConfigs, getFilteredExamConfigs, deleteExamConfig } from "../../services/ExamConfigService";
import { getExams, getFilteredExams } from "../../services/ExamService";
import { enqueueSnackbar } from "notistack";
import Pagination from "../../components/Pagination/Pagination";

const ExamConfigList = () => {
  const [subjectData, setSubjectData] = useState([]);
  const [examList, setExamList] = useState([]);
  const [examConfigData, setExamConfigData] = useState([]);
  const [last_page, setLastPage] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [program_id, setProgramID] = useState('');
  const [subject_id, setSubjectID] = useState('');
  const [evaluation_type_id, setEvaluationTypeID] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [name, setName] = useState('');
  const [term, setTerm] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const statusData = [
    { id: 1, name: "Not Graded" },
    { id: 2, name: "In Progress" },
    { id: 3, name: "Graded" },
  ];
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);
  const session_id = localStorage.getItem("session_id");

  useEffect(() => {
    const org_id = localStorage.getItem("organization_id");
    if (!org_id || !session_id) return;

    dispatch(fetchPrograms());
    dispatch(fetchSubjects({ selected: 0 }));
    dispatch(fetchEvaluationTypes());
    getExamConfigList({ selected: 0 });
  }, []);

  const getExamConfigList = async ({ selected }) => {
    setSearch(false);
    let current_page = selected + 1;
    try {
      setIsLoader(true);
      setCurrentPage(current_page);
      const response = await getExamConfigs(current_page);
      // const response = await getExams(session_id, current_page);
      if (response.status === 200) {
        const eventData = response.data.data;
        let last_page = response.data.last_page;
        setExamConfigData(eventData);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  };

  const getSubjectList = async (id) => {
    try {
      const response = await getProgramWiseSubjectList(id);
      if (response.status === 200) {
        const subjectData = response.data.data;
        setSubjectData(subjectData);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleSearch = useCallback(async ({ selected }) => {
    const page = selected + 1;
    try {
      setSearch(true);
      setIsLoader(true);
      setCurrentPage(page);
      const response = await getFilteredExamConfigs(name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      // const response = await getFilteredExams(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const { data, last_page } = response.data;
        setExamConfigData(data);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  }, [program_id, subject_id, evaluation_type_id, name, start, end, term, status, session_id])

  // <--- Make sure the dependencies are correctly listed

  const handleReset = (event) => {
    event.preventDefault();
    setProgramID('');
    setSubjectID('');
    setName('');
    setEvaluationTypeID('');
    setStart('');
    setEnd('');
    setTerm('');
    setStatus('');
    getExamConfigList({ selected: 0 });
  };

  const getExamList = async (program_id) => {
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        const examList = response.data.data;
        setExamList(examList)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteExamConfiguration = async (id) => {
    try {
      const response = await deleteExamConfig(id);
      if (response.status === 200) {
        enqueueSnackbar("Exam deleted successfully", { variant: "success" });
        getExamConfigList({ selected: 0 });
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setAdvancedSearch(!advancedSearch)
  };// Add program_id and search to the dependencies

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramID(value);
      getExamList(value);
      getSubjectList(value);
      setSearch(true); // Set search flag to true when program_id changes
    }
    else if (name === 'subject_id') {
      setSubjectID(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'evaluation_type_id') {
      setEvaluationTypeID(value);
    }
    else if (name === 'start') {
      setStart(value);
    }
    else if (name === 'end') {
      setEnd(value);
    }
    else if (name === 'term') {
      setTerm(value);
    }
    else if (name === 'status') {
      setStatus(value);
    }
  };

  const ExamConfigHideAndShowHandler = async (e, item) => {
    try {
      let is_active = e.target.checked ? 1 : 0;
      const data = { is_active, ExamConfig_id: item.id };
      const response = await examActiveInActive(data);
      if (response.status === 200) {
        enqueueSnackbar("Exam status updated successfully", { variant: "success" });
        getExamConfigList({ selected: 0 });
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };


  return (
    <div className="card">
      {isLoader ? (
        <Loader />
      ) : (
        <div className="">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <div className="page-title d-flex align-items-center">
                <Button className="btn-success" onClick={advancedSearchHandler}>
                  <FaSearch /> Search
                </Button>
              </div>
              <div>
                {permissions && permissions.includes("exams.config.bulk.create") && (
                  <Link className="btn btn-primary" to="/bulk/exam/config/create">
                    Exam Config Bulk Create
                  </Link>
                )}
                {permissions && permissions.includes("exam.config.create") && (
                  <Link className="btn btn-primary mx-2" to="/exam/config/create">
                    Add
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className={`filter-ExamConfig ${advancedSearch ? 'show' : 'hide'} mb-5`} id="advanced-search">
              <AssessmentFilter
                evaluationType={evaluationType}
                programData={programData}
                subjectData={subjectData}
                examList={examList}
                subject_id={subject_id}
                program_id={program_id}
                evaluation_type_id={evaluation_type_id}
                name={name}
                start={start}
                end={end}
                term={term}
                status={status}
                onChangeHandler={changeHandler}
                statusData={statusData}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </div>

            {examConfigData && examConfigData.length > 0 ? (
              <div className="evaluating-table ExamConfig-tablecus">
                <Table
                  cols={tableConstants(deleteExamConfiguration, permissions, current_page)}
                  data={examConfigData}
                  hoverable={true}
                  striped={true}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">No data found</div>
            )}
          </div>
        </div>
      )}
      {examConfigData && examConfigData.length > 0 && (
        <div className="card-footer">
          <Pagination
            totalPage={last_page}
            currentPage={current_page - 1}
            handlePageChange={(i) => search ? handleSearch(i) : getExamConfigList(i)}
          />
        </div>
      )}
    </div>
  );
};

export default ExamConfigList;
