import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getAssessmentLists = async () => {
  try {
    const response = await axios.get(ENDPOINTS.ASSESSMENT_LIST.GET);
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getAssessmentListById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.ASSESSMENT_LIST.GET_ASSESSMENT_LIST}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createAssessmentList = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ASSESSMENT_LIST.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAssessmentList = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.ASSESSMENT_LIST.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAssessmentList = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.ASSESSMENT_LIST.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
