import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Multiselect } from 'multiselect-react-dropdown';
import Card from '../../components/Card/Card';

const TeacherSubjectMappingForm = ({ handleSubmit, classInChargeAdd, classInChargeRemove,
  handleChange, onSubjectRemove, handleChangeSubject, user,
  clickOnDelete, addNewRow, programs, programData, subjectData, class_in_charge_ids, back
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-12">
          <Card componentName={`MAPPING OF ${user.name}`} back={back}>
            <div className="row">
              <div className='col-md-12'>
                <span>If you want to make class In-charge please select class below -</span>
              </div>
              <div className="col-md-10">
                <label>Class InCharge</label>
                <Multiselect
                  name={`class_in_charge_ids`}
                  options={programData}
                  id={'class_in_charge_ids'}
                  selectedValues={class_in_charge_ids}
                  onRemove={(e) => classInChargeRemove(e)}
                  displayValue="name"
                  showCheckbox={true}
                  closeOnSelect={false}
                  onSelect={(e) => classInChargeAdd(e)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className='row mt-3'>
                <div className="col-md-12">
                  <span>If you want to assign class wise subject please select class and subject below -</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6"><label>Class</label></div>
                <div className="col-md-6"><label>Subject</label></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {programs && programs.map((val, idx) => (
                  <div className="row mt-2" key={idx}>
                    <div className="col-md-5">
                      <select
                        name="id"
                        data-id={idx}
                        className="form-select form-control cursor-pointer"
                        onChange={(e) => handleChange(e, idx)}
                        placeholder="Select class"
                        value={val ? val.id : ''}
                      >
                        <option value='all'>Choose class</option>
                        {programData.map((program) => (
                          <option value={program.id} key={program.id}>
                            {program.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-5">
                      <Multiselect
                        name={`program_${idx}_subjects`}
                        options={val && val.subjectData ? val.subjectData : subjectData}
                        data-id={idx}
                        id={val && val.subjects ? val.subjects : []}
                        selectedValues={val && val.subjects ? val.subjects : []}
                        onRemove={(e) => onSubjectRemove(e, val.id)}
                        displayValue="name"
                        showCheckbox={true}
                        closeOnSelect={false}
                        onSelect={(e) => handleChangeSubject(e, idx)}
                      />
                    </div>
                    <div className="col-md-2">
                      {idx === 0 ? (
                        <button
                          onClick={addNewRow}
                          type="button"
                          className="btn btn-primary text-center"
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger"
                          onClick={() => clickOnDelete(val)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <div className="col-md-2">
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </form>
  );
};

export default TeacherSubjectMappingForm;
