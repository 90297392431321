import React from 'react';

const Input = ({
  label,
  type = 'text',
  name,
  value,
  onChangeHandler,
  placeholder,
  disabled = false,
  required = false,
  maxLength,
  minLength,
  pattern,
  autoComplete = 'off',
  error,
  className,
  id = "",
  ...rest
}) => {
  return (
    <React.Fragment>
      {label && <label htmlFor={name} className='mb-1 mt-2'>{label}{required && <i className="text-danger">*</i>}</label>}
      <input
        type={type}
        name={name}
        id={id ? id : name}
        value={value}
        onChange={onChangeHandler}
        className={`form-control ${className}`}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        maxLength={maxLength}
        minLength={minLength}
        pattern={pattern}
        autoComplete={autoComplete}
        aria-invalid={!!error}
        {...rest}
      />
      {error && <span className="error-message">{error}</span>}
    </React.Fragment>
  );
};

export default Input;
