import React from "react";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const tableConstants = (permissions, deleteHandler, showModel) => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Title',
      render: rowData => {
        return <span>{rowData.title}</span>;
      },
    },
    {
      title: 'Is_Holiday',
      render: rowData => {
        return <span>{rowData.is_holiday === "1" ? "YES" : "NO"}</span>;
      },
    },
    {
      title: 'Event_Date',
      render: rowData => {
        return <span>{rowData.event_date}</span>;
      },
    },
    {
      title: 'Description',
      render: rowData => {
        return <div dangerouslySetInnerHTML={{ __html: rowData.description }} />;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          {permissions.includes("event.calendar.update") && (
            <FontAwesomeIcon
              icon={faEdit}
              color="#321fdb"
              style={{
                marginRight: "10px",
                cursor: "pointer"
              }}
              onClick={() => showModel(rowData)}
            />)}
          {permissions.includes("event.calendar.delete") &&
            <FontAwesomeIcon
              icon={faTrashAlt}
              color="#F44336"
              style={{
                marginRight: "10px",
                cursor: "pointer"
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you wish to delete this item?"
                  )
                ) {
                  deleteHandler(rowData);
                }
              }}
            />
          }
        </span>;
      },
    },
  ];
  return formatedData;
};
