import React, { useEffect, useState } from 'react';
import StudentForm from './StudentForm';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchPrograms } from '../../redux/program/programActions';
import * as XLSX from 'xlsx';
import { Button, Alert } from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';
import ExcelDataSample from './StudentExcelSample.json';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from '../../components/Card/CardHeader';
import Loader from '../../components/Loader/Loader';
import { getClassWiseSubjects } from '../../services/SubjectServices';
import { createBulkStudents, createStudent, getStudentById, updateBulkStudents, updateStudent } from '../../services/StudentService';
import { getStudentEnrolledSubjects, createStudentSubjectsEnrollment } from '../../services/StudentSubjectMappingService';
import { removeStudentExamsEnrollment, addStudentExamsEnrollment } from '../../services/ExamService';
import StudentSubjectEnrollment from '../StudentSubjectMapping/StudentSubjectEnroll/StudentSubjectEnrollment';
import Modal from '../../components/Modal/Modal';
import { termOptionsMap } from '../../globalConstants';

const StudentManage = () => {
  const initialState = {
    first_name: '', last_name: '', id_number: '',
    roll_no: '', user_name: '', email: '', gender: '',
    dob: '', phone1: '', phone2: '', category: '', apaar_id: '',
    aadhar_number: '', father_name: '', mother_name: '', house_name: '',
    suspended: 0, program_id: '',
  };
  const [state, setState] = useState(initialState);
  const [loader, setLoader] = useState(false);
  const [enrollmentLoader, setEnrollmentLoader] = useState(false);
  const [image, setImage] = useState(null);
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [preview, setPreview] = useState(null);
  const [isBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [subjectIds, setSubjectIds] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [removeSubjectIds, setRemoveSubjectIds] = useState([]);
  const [addSubjectIds, setAddSubjectIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [markedEventMessages, setMarkedEventMessages] = useState([]);
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data ?? []);


  const changeHandler = (event) => {
    if (event.target.name === 'program_id') {
      if (subjectIds.length > 0) {
        enqueueSnackbar("You cannot update the section until the subject enrollment for the current class is removed.", { variant: "error" });
        return;
      }
      getSubjectList(event.target.value);
    }
    console.log(event.target.name, event.target.value);
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  }


  const fetchStudentMappedSubjects = async (student_id, program_id) => {
    try {
      setEnrollmentLoader(true);
      const response = await getStudentEnrolledSubjects(student_id, program_id);
      if (response.status === 200) {
        const subjectIDS = response.data.data.map((subject) => subject.subject_id.toString());
        setSubjectIds(subjectIDS);
      }
    }
    catch (error) {
      console.log("Error", error);
    }
    finally {
      setEnrollmentLoader(false);
    }
  }

  const fetchStudentById = async (id) => {
    const session_id = localStorage.getItem('session_id');
    try {
      setLoader(true);
      setEnrollmentLoader(true);
      const response = await getStudentById(id, session_id);
      if (response.status === 200) {
        const data = response.data.data;
        data.program_id = data.programs && data.programs.id;
        fetchStudentMappedSubjects(id, data.program_id);
        getSubjectList(data.program_id);
        setPreview(data.profile_image);
        setState({
          ...data, gender: data.gender.toLowerCase(),
          dob: moment.unix(data.dob).format('YYYY-MM-DD'),
          roll_no: data.roll_no.toString(),
        });
        delete data.programs;
        delete data.created_at;
        delete data.organization_id;
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (id)
      fetchStudentById(id);
    dispatch(fetchPrograms());
  }, []);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!image) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(image)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [image])


  const excelFileReader = (e) => {
    const session_id = localStorage.getItem('session_id');
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const result = {};
      workbook.SheetNames.forEach(sheet => {
        const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        result[sheet] = json_data;
      });

      let final_data = result['Sheet1'];
      let classNameIdMap = [];
      programData.forEach((item) => {
        let key = item.name.toLowerCase().replace(/\s/g, '');
        classNameIdMap[key] = item.id
      })

      // Now 'result' contains JSON data for each sheet
      let finalData = final_data && final_data.map((item, index) => {
        let className = item.class && item.class.toLowerCase().replace(/\s/g, '');
        let program_id = classNameIdMap[className];
        let phone1 = item.phone1;

        if (!program_id) {
          enqueueSnackbar(`Program not found for class ${item.class} at line no ${index + 1}`, { variant: "error" });
        }

        if (!phone1) { enqueueSnackbar(`Phone1 is required at line no ${index + 1}`, { variant: "error" }); }

        let { first_name, last_name, roll_no, user_name,
          email, id_number, gender, dob, phone2, category,
          aadhar_number, father_name, mother_name, house_name,
          suspended, profile_image
        } = item;

        return {
          uuid: id_number, first_name, last_name,
          roll_no, user_name, email, id_number, gender,
          dob, phone1, phone2, category, aadhar_number,
          father_name, mother_name, house_name, suspended,
          program_id, session_id: session_id, profile_image
        };
      });
      setFile(finalData);
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(ExcelDataSample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `student-bulk-upload-sample.xlsx`);
  };

  // Note: Use readAsBinaryString instead of readAsArrayBuffer to handle binary data for XLSX.
  const studentBulkUpload = async () => {
    if (file === null || file === undefined) {
      enqueueSnackbar("Please select a file", { variant: "error" });
      return;
    }
    else {
      let data = { file };
      try {
        setLoader(true);
        let response = updateFlag ? await updateBulkStudents(data) : await createBulkStudents(data);
        setLoader(false);
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: "success" });
          navigate('/student/list');
        }
        else {
          console.log(response.data.message.error, "error");
          enqueueSnackbar(response.data.message.error, { variant: "error" });
        }
      } catch (error) {
        setLoader(false);
        if (error.response && error.response.status === 422) {
          let errorObject = error.response.data.message.errors
          setErrors(errorObject);
          // Handle 422 error
          if (errorObject.hasOwnProperty('file')) {
            for (let key in errorObject) {
              if (errorObject.hasOwnProperty(key)) {
                // Access the array elements for each property
                errorObject[key].forEach(error => {
                  enqueueSnackbar(error, { variant: "error" });
                  console.log(error);
                });
              }
            }
          } else {
            // Handle other errors
            console.error('Request failed:', error.message);
          }
          console.log(error.response.data.message.errors, "error");
        }
      }
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');

    const { id_number, first_name, last_name, roll_no, user_name, email,
      gender, dob, phone1, phone2, category, apaar_id, aadhar_number, father_name,
      mother_name, house_name, suspended, program_id,
    } = state;
    if (!id_number || !first_name || !roll_no || !user_name)
      return enqueueSnackbar("Please fill all the required fields", { variant: "error" });

    const data = new FormData();
    data.append('image', image);
    data.append('id_number', id_number);
    data.append('uuid', id_number);
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('roll_no', roll_no);
    data.append('user_name', user_name);
    data.append('email', email);
    data.append('gender', gender);
    data.append('dob', dob);
    data.append('phone1', phone1);
    data.append('phone2', phone2);
    data.append('category', category);
    data.append('apaar_id', apaar_id);
    data.append('aadhar_number', aadhar_number);
    data.append('father_name', father_name);
    data.append('mother_name', mother_name);
    data.append('house_name', house_name);
    data.append('suspended', suspended);
    data.append('program_id', program_id);
    data.append('session_id', session_id);

    setLoader(true);
    if (id) {
      data.append('id', id);
      try {
        const response = await updateStudent(id, data);
        console.log(response, "response");
        setLoader(false);
        if (response.status === 200) {
          setLoader(false);
          enqueueSnackbar(response.data.message.success, { variant: "success" });
          // navigate('/student/list');
          // setState(initialState);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
      return;
    }

    try {
      const response = await createStudent(data);
      setLoader(false);
      if (response.status === 200) {
        setLoader(false);
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        fetchStudentById(response.data.data.id);
        navigate(`/student/update/${response.data.data.id}`);
        // setState(initialState);
      }
    } catch (error) {
      setLoader(false);
      enqueueSnackbar(error.response.data.message, { variant: "error" });
      return error;
    }
  }

  const back = () => {
    navigate('/student/list');
  }

  const getSubjectList = async (programId) => {
    try {
      const res = await getClassWiseSubjects(programId);
      const subjectData = res.data.data;
      setSubjectList(subjectData);
    } catch (error) {
      console.log(error);
    }
  };

  // StudentSubjectEnrollment Component submission and change handlers
  const subjectChangeHandler = (event) => {
    const subject_id = event.target.value;
    let updatedSubjectIds;

    if (event.target.checked) {
      updatedSubjectIds = [...subjectIds, subject_id];
      removeSubjectIds.splice(removeSubjectIds.indexOf(subject_id), 1);
    } else {
      setRemoveSubjectIds([...removeSubjectIds, subject_id]);
      updatedSubjectIds = subjectIds.filter(id => id !== subject_id);

    }
    setSubjectIds(updatedSubjectIds);
    setAddSubjectIds(updatedSubjectIds);
  };

  const selectAllSubjectHandler = (event) => {
    const updatedSubjectIds = event.target.checked
      ? subjectList.map(subject => subject.id.toString())
      : [];
    setSubjectIds(updatedSubjectIds);
    setAddSubjectIds(updatedSubjectIds);

    const updatedRemoveSubjectIds = event.target.checked
      ? [] : subjectList.map(subject => subject.id.toString());
    setRemoveSubjectIds(updatedRemoveSubjectIds);
  };

  const removeEnrollmentFromExams = async (student_id, program_id, subject_ids) => {
    try {
      const response = await removeStudentExamsEnrollment({ student_id, program_id, subject_ids });
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        setIsOpen(true);
        setSuccess(response.data.message.success.success);
        setError(response.data.message.success.error);
        setMarkedEventMessages(response.data.message.success.marked_event_messages);

      }
    }
    catch (error) {
      console.error(error);
      const res = error.response;
      console.log(res, "error response");
    }
  };

  const addEnrollmentToExams = async (student_id, program_id, subject_ids) => {
    try {
      const response = await addStudentExamsEnrollment({ student_id, program_id, subject_ids });
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const submitSubjectEnrollment = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem("session_id");
    const data = { session_id, program_id: state.program_id, student_id: id, subject_ids: subjectIds };
    try {
      setEnrollmentLoader(true);
      const response = await createStudentSubjectsEnrollment(data);
      if (response.status === 201) {
        if (removeSubjectIds.length > 0)
          removeEnrollmentFromExams(id, state.program_id, removeSubjectIds);
        if (addSubjectIds.length > 0)
          addEnrollmentToExams(id, state.program_id, addSubjectIds);
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setEnrollmentLoader(false);
    }
  };

  return (
    <div className="card">
      <CardHeader
        title={id ? 'Update Student' : 'Add Student'}
        icon={''}
        button={<div className="">
          {permissions.includes('bulk.upload.student') ?
            <React.Fragment>
              {id ? "" :
                <Button variant="primary"
                  className="mx-1"
                  onClick={() => setIsBulk(!isBulk)}>
                  {isBulk ? "Manual Create" : "Bulk Upload"}
                </Button>
              }
            </React.Fragment> : ""
          }
          <Button
            variant="secondary"
            className="mx-1"
            onClick={back}>
            Back
          </Button>
        </div>
        }
      />
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Subject and Exam Enrollment"
      >
        <div className="mt-5">
          {error && <Alert key={'1'} variant={'danger'} style={{ color: 'red' }}>
            <h6>
              {error}
            </h6>
            <ol>
              {
                markedEventMessages.length > 0 && markedEventMessages.map((message, index) => {
                  return <li key={index}>
                    {message.event_name} : {message.subject_name} for {termOptionsMap[message.term]}
                  </li>
                })
              }
            </ol>
          </Alert>}
          {success && <Alert key={'1'} variant={'success'} style={{ color: 'green' }}>
            {success}
          </Alert>}
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              size=""
              onClick={() => { setIsOpen(false); setError(''); setSuccess('') }}> OK</Button>
          </div>
        </div>
      </Modal>
      <div className="card-body">
        {loader ? <Loader /> :
          <StudentForm
            changeHandler={changeHandler}
            submitHandler={submitHandler}
            loader={loader}
            programData={programData}
            data={state}
            image={image}
            setImage={setImage}
            preview={preview}
            id={id}
            updateFlag={updateFlag}
            setUpdateFlag={setUpdateFlag}
            subjectList={subjectList}
            isBulk={isBulk}
            bulkUploadFile={studentBulkUpload}
            excelFileReader={excelFileReader}
            downloadExcel={downloadExcel}
          />
        }
        {id &&
          <React.Fragment>
            {enrollmentLoader ? <Loader /> : <React.Fragment>
              <div className="card-header mt-2">
                <h5 style={{ color: "blue" }}>Subject Enrollment</h5>
              </div>
              <StudentSubjectEnrollment
                student_id={id}
                program_id={state.program_id}
                subject_ids={subjectIds}
                subjectData={subjectList}
                submitHandler={submitSubjectEnrollment}
                changeHandler={changeHandler}
                subjectChangeHandler={subjectChangeHandler}
                selectAllSubjectHandler={selectAllSubjectHandler}
              />
              <div className="clearfix d-flex justify-content-end">
                <Button variant="primary" className="mt-3" onClick={submitSubjectEnrollment}>
                  Save
                </Button>
              </div>
            </React.Fragment>}
          </React.Fragment>
        }
        {errors && Object.keys(errors).length > 0 &&
          <div className="alert alert-danger">
            <ul>
              {Object.keys(errors).map((key, index) => {
                // Check if the error message is an object and convert it to a string if it is
                const errorMessage = typeof errors[key] === 'object' ? JSON.stringify(errors[key]) : errors[key];
                return <li key={index}>{errorMessage}</li>;
              })}
            </ul>
          </div>
        }
      </div>
    </div>
  );
};
export default StudentManage;
