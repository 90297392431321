import React from 'react';
import ExcelBulkUpload from '../../ExcelBulkUpload/ExcelBulkUpload';

const AttendanceBulkUpload = ({
  downloadExcel,
  excelFileReader,
  bulkUpload,
  loader,
  errorMessage,
}) => {
  return (
    <React.Fragment>
      <ExcelBulkUpload
        downloadExcel={downloadExcel}
        excelFileReader={excelFileReader}
        bulkUploadFile={bulkUpload}
        loader={loader}
      />
      {errorMessage && errorMessage.map(item => <div className="d-flex justify-content-center">
        <div className='alert alert-danger col-md-10'>{item}</div></div>)}
    </React.Fragment>
  );
};

export default AttendanceBulkUpload;

