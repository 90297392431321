import React, { useState, useEffect } from 'react';
import Card from '../../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import CircularForm from './CircularForm';
import { getPrograms } from '../../../services/ProgramServices';
import { getCircularById, createCircular, updateCircular } from '../../../services/AppManagement/CircularService';
import Loader from '../../../components/Loader/Loader';

const CircularManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [circular_topic, setCircularTopic] = useState('');
  const [circular_desc, setCircularDesc] = useState('');
  const [circular_no, setCircularNo] = useState('');
  const [is_ews, setIsEws] = useState(false);
  const [program_ids, setProgramIds] = useState([]);
  const [circular_file, setCircularFile] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [loading, setLoading] = useState(false);
  const id = params.id;

  const getUniqueCircular = async () => {
    try {
      const response = await getCircularById(id);
      if (response.status === 200) {
        const data = response.data.data;
        setCircularTopic(data.circular_topic);
        setCircularDesc(data.circular_desc);
        setCircularNo(data.circular_no);
        setIsEws(data.is_ews);
        let programs = data.circular_programs.map((val) => val.program.id.toString());
        setProgramIds(programs);
        setCircularFile(data.circular_file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'circular_topic')
      setCircularTopic(value);
    else if (name === 'circular_desc')
      setCircularDesc(value);
    else if (name === 'circular_no')
      setCircularNo(value);
    else if (name === 'is_ews')
      setIsEws(value);
  };

  const handleInputChange = (event) => {
    const progId = event.target.value;
    let updatedProgramIds;

    if (event.target.checked)
      updatedProgramIds = [...program_ids, progId];
    else
      updatedProgramIds = program_ids.filter(id => id !== progId);
    setProgramIds(updatedProgramIds);
  };

  const handleSwitchChange = (event) => {
    const updatedProgramIds = event.target.checked
      ? programData.map(program => program.id.toString())
      : [];
    setProgramIds(updatedProgramIds);
  };


  useEffect(() => {
    async function fetchPrograms() {
      try {
        const response = await getPrograms();
        if (response.success) {
          setProgramData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPrograms();
    if (id) {
      getUniqueCircular();
    }
  }, []);

  const fileUploadHandler = (e) => {
    const files = e.target.files
    setCircularFile(files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');

    const formData = new FormData();
    formData.append('session_id', session_id);
    formData.append('circular_file', circular_file);
    formData.append('circular_topic', circular_topic);
    formData.append('circular_desc', circular_desc);
    formData.append('circular_no', circular_no);
    formData.append('is_ews', is_ews);
    program_ids.forEach((id, idx) => {
      formData.append(`program_ids[${idx}]`, id);
    });
    // Append each file to the form data
    if (id) {
      formData.append('id', id);
    }

    try {
      setLoading(true);
      const response = id
        ? await updateCircular(id, formData)
        : await createCircular(formData);
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        navigate('/circular/list');
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: 'error' });
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  const back = () => {
    navigate('/circular/list');
  };

  return (
    <Card componentName={id ? 'Update Circular' : 'Add Circular'} back={back}>
      {loading ? <Loader /> :
        <CircularForm
          submitHandler={handleSubmit}
          changeHandler={changeHandler}
          fileUploadHandler={fileUploadHandler}
          handleInputChange={handleInputChange}
          handleSwitchChange={handleSwitchChange}
          id={id}
          loading={loading}
          programData={programData}
          program_ids={program_ids}
          circular_file={circular_file}
          circular_desc={circular_desc}
          circular_topic={circular_topic}
          circular_no={circular_no}
          is_ews={is_ews}
        />}
    </Card>
  );
};
export default CircularManage;
