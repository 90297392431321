import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getTimeTableById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.TIME_TABLE.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTimeTables = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.TIME_TABLE.GET, {
      params: {
        session_id: session_id,
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createTimeTable = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.TIME_TABLE.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateTimeTable = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.TIME_TABLE.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTimeTable = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.TIME_TABLE.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getUploadTimeTableById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.UPLOAD_TIME_TABLE.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getUploadTimeTables = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.UPLOAD_TIME_TABLE.GET, {
      params: {
        session_id: session_id,
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createUploadTimeTable = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.UPLOAD_TIME_TABLE.CREATE, data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateUploadTimeTable = async (id, data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.UPLOAD_TIME_TABLE.UPDATE}/${id}`, data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteUploadTimeTable = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.UPLOAD_TIME_TABLE.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

