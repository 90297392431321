import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { FaListAlt } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import { enqueueSnackbar } from "notistack";
import { deleteEvaluationType } from "../../services/EvaluationTypeService";
import CardHeader from "../../components/Card/CardHeader";
import ListCard from "../../components/Card/ListCard";

const EvaluationTypeList = () => {
  const [permissions] = useState(JSON.parse(localStorage.getItem("permissions")));

  const dispatch = useDispatch();
  const evaluationTypeData = useSelector((state) => state.evaluationType.evaluationTypes.data);
  const lastPage = useSelector((state) => state.evaluationType.evaluationTypes.last_page ? state.evaluationType.evaluationTypes.last_page : 1);

  useEffect(() => {
    dispatch(fetchEvaluationTypes());
  }, [dispatch]);

  const deleteEvaluation = async (id) => {
    try {
      const response = await deleteEvaluationType(id);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        dispatch(fetchEvaluationTypes());
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="card">
      {!evaluationTypeData ? (
        <Loader />
      ) : (
        <div className="evaluation-list">
          <div className="animated">
            <CardHeader
              title="Evaluation"
              icon={<FaListAlt />}
              button={permissions.includes("evaluationtypes.create") && (
                <Link className="btn btn-primary" to="/evaluation/type/create">
                  Add Evaluation
                </Link>
              )}
            />
            <div className="card-body row">
              {evaluationTypeData &&
                evaluationTypeData.map((item, idx) => (
                  <ListCard
                    key={idx}
                    permissions={permissions}
                    updatePermission="evaluationtypes.update"
                    deletePermission="evaluationtypes.delete"
                    updateUrl={`/evaluation/type/update/${item.id}`}
                    icon="fa-solid fa-clipboard-list pl-1"
                    item={item}
                    onDelete={deleteEvaluation}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      <div className="card-footer">
        <Pagination
          totalPage={lastPage}
          handlePageChange={(i) => dispatch(fetchEvaluationTypes(i))}
        />
      </div>
    </div>
  );
};

export default EvaluationTypeList;
