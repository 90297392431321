import React from "react";
import { faClone, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (examDelete, permissions, page = 1) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{100 * (Number(page) - 1) + (count++)}</span>;
      },
    },
    {
      title: 'Exam Header',
      render: rowData => {
        return <span>{rowData.name.name}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
          {rowData.term === "UT-1" ? "UT-I" : null}
          {rowData.term === "UT-2" ? "UT-II" : null}
          {rowData.term === "UT-3" ? "UT-III" : null}
          {rowData.term === "PT-1" ? "PT-I" : null}
          {rowData.term === "PT-2" ? "PT-II" : null}
          {rowData.term === "PT-3" ? "PT-III" : null}
          {rowData.term === "1" ? "Term-I" : null}
          {rowData.term === "3" ? "Common Pre-Board" : null}
          {rowData.term === "4" ? "Pre-Board" : null}
          {rowData.term === "2" ? "Term-II" : null}
          {rowData.term === "5" ? "Other" : null}
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },

    {
      title: 'Subject',
      render: rowData => {
        return <span>{rowData.subject.name}</span>;
      },
    },

    {
      title: 'Evaluation',
      render: rowData => {
        return <span>{rowData.evaluation_type.name}</span>;
      },
    },

    {
      title: 'Start',
      render: rowData => {
        return <span>{rowData.start}</span>;
      },
    },
    {
      title: 'End',
      render: rowData => {
        return <span>{rowData.end}</span>;
      },
    },
    {
      title: 'Max Score',
      render: rowData => {
        return <span>{rowData.max_score}</span>;
      },
    },
  ];

  if (Array.isArray(permissions) && (permissions.includes("exams.update") || permissions.includes("exams.delete"))) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("exams.update") &&
              <Link to={"/exam/config/update/" + rowData.id}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>}
            &nbsp;&nbsp;
            {permissions.includes("exams.delete") &&
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (window.confirm("Are you sure you wish to delete this rowData?"))
                    examDelete(rowData.id);
                }}
                icon={faTrash} color="red" />}
            &nbsp;&nbsp;
            {permissions.includes("exams.update") &&
              <Link to={"/exam/clone/config/create/" + rowData.id}>
                <FontAwesomeIcon icon={faClone} />
              </Link>}
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
