import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const viewReportCard = async (student_id, session_id, term) => {
  try {
    const response = await axios.get(`${ENDPOINTS.REPORT_CARD.VIEW}/${student_id}/${session_id}/${term}`);
    return response;
  }catch (error) {
    throw error;
  }
}

export const downloadReportCard = async (student_id, session_id, term, responseType) => {
  try {
    const response = await axios.get(`${ENDPOINTS.REPORT_CARD.DOWNLOAD}/${student_id}/${session_id}/${term}`, responseType);
    return response;
  } catch (error) {
    throw error;
  }
}

export const downloadExcelReport = async (program_id, session_id, term, responseType) => {
  try {
    const response = await axios.get(`${ENDPOINTS.REPORT_CARD.EXCEL_RESULT}/${program_id}/${session_id}/${term}`, responseType);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPublishResultList = async (session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.REPORT_CARD.PUBLISH_RESULT_LIST, {
      params: {
        session_id: session_id
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const publishResult = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.REPORT_CARD.PUBLISH_RESULT, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePublishResult = async (id, data) => {
  try {
    const response = await axios.patch(`${ENDPOINTS.REPORT_CARD.UPDATE_PUBLISH_RESULT}/${id}`, data);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getStudentListPublishReportCardById = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.REPORT_CARD.GET_PUBLISHED_RESULT}/${id}`, {
      params: {
        session_id: session_id,
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

