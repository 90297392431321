import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FaUserCog } from "react-icons/fa";
import Table from "../../components/Table/Table";
import { tableConstants } from "./RoleTableConstant";
import Loader from "../../components/Loader/Loader";
import { getRolePermissions } from '../../services/RolePermissionService';
import CardHeader from '../../components/Card/CardHeader';

const RoleList = () => {
  const [rolesData, setRolesData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const fetchRoles = async (page = 1) => {
    setIsLoader(true);
    try {
      const rolesResponse = await getRolePermissions(page);
      if (rolesResponse.status === 200) {
        const rolesData = rolesResponse.data.data;
        setRolesData(rolesData);
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <React.Fragment>
      {isLoader ? (<Loader />) :
        (<div className="program-list">
          <div className="animated">
            <div className="card">
              <CardHeader
                title="Role List"
                icon={<FaUserCog />}
                button={
                  permissions.includes("role.create") && (
                    <Link className="btn btn-primary" to="/role/permission">
                      Add Role
                    </Link>
                  )
                }
              />
              <div className="card-body evaluating-table">
                {rolesData && rolesData.length > 0 ?
                  <Table
                    cols={tableConstants(permissions)}
                    data={rolesData}
                    striped={true}
                    hoverable={true}
                  />
                  : <div className="d-flex flex-column align-items-center
                  justify-content-center vh-100">
                    Data not found
                  </div>
                }
              </div>
            </div>
          </div>
        </div>)}
    </React.Fragment>
  );
};

export default RoleList;
