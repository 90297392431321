import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Switch from "../../components/Switch/Switch";

export const tableConstants = (permissions, statusHandler, currentPage) => {
  let count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{(currentPage - 1) * 50 + count++}</span>;
      },
    },
    {
      title: 'Name',
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: 'Email',
      render: rowData => {
        return <span>{rowData.email}</span>;
      },
    },
    {
      title: 'Phone',
      render: rowData => {
        return <span>{rowData.phone}</span>;
      },
    },
    {
      title: 'Role',
      render: rowData => {
        return <span>{rowData.role ? rowData.role.name : ""}</span>;
      },
    },
  ];

  if (permissions.includes("users.update")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("users.update") &&
              <Link to={`/user/update/${rowData.id}`}>
                <FontAwesomeIcon
                  icon={faEdit}
                  color="#321fdb"
                />
              </Link>}
          </React.Fragment>
        );
      },
    });
  }
  if (permissions.includes("users.status.update")) {
    formatedData.push({
      title: 'Status',
      render: rowData => {
        return (
          <React.Fragment>
            <Switch
              checked={Number(rowData.status) === 1 ? true : false}
              id={`custom-switch-${rowData.id}`}
              onChange={() => {
                if (
                  window.confirm(
                    "Are you sure you wish to change the user status?"
                  )
                )
                  statusHandler(rowData.id, Number(rowData.status) === 1 ? 0 : 1)
              }}
            />
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
