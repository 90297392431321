import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import Card from '../../components/Card/Card';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import {getAssessmentListById, createAssessmentList, updateAssessmentList } from '../../services/AssessmentListService';
import AssessmentListForm from './AssessmentListForm';

const ExamManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    id: null,
    loader: false,
    name: '',
    position: '',
    term: '',
    program_ids: [],
  });
  const programData = useSelector((state) => state.program.programs.data ?? []);

  useEffect(() => {
    if (params.id) {
      getUniqueTemplate();
    }
    dispatch(fetchPrograms());
  }, [params.id, fetchPrograms]);

  const getUniqueTemplate = async () => {
    setState({ ...state, loader: true });
    try {
      const response = await getAssessmentListById(params.id);
      if (response.status === 200) {
        const { id, name, position, term } = response.data.data;
        let program_ids = response.data.data.programs && response.data.data.programs.map((item) => item.id.toString());
        setState({ ...state, id, name, position, term, program_ids, loader: false });
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loader: false });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState({ ...state, loader: false });
    let session_id = localStorage.getItem('session_id');
    let organization_id = localStorage.getItem('organization_id');
    const { id, name, term, program_ids, position } = state;
    const data = { organization_id, session_id, name, term, position, program_ids };

    try {
      const response = id
        ? await updateAssessmentList(id, data)
        : await createAssessmentList(data);

      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        navigate('/assessment/list');
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loader: true });
    }
  };

  const handleInputChange = (event) => {
    const prog_id = event.target.value;
    const value = event.target.value;
    let program_ids;

    if (event.target.checked) {
      program_ids = [...state.program_ids, prog_id];
    } else {
      program_ids = state.program_ids.filter((id) => id !== value);
    }

    setState({ ...state, program_ids });
  };

  const handleSwitchChange = (event) => {
    let program_ids;

    if (event.target.checked) {
      program_ids = programData.map((program) => program.id.toString());
    } else {
      program_ids = [];
    }

    setState({ ...state, program_ids });
  };
  const back = () => {
    navigate('/assessment/list');
  };

  return (
    <Card componentName={state.id ? 'Update Assessment' : 'Add Assessment'} back={back}>
      {state.loader ? (
        <Loader />
      ) : (
        <AssessmentListForm
          state={state}
          programData={programData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          handleSwitchChange={handleSwitchChange}
        />
      )}
    </Card>
  );
};

export default ExamManage;

