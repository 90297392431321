import React, { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import { tableConstants } from "./UploadTimeTableConstant";
import Table from "../../../../components/Table/Table";
import Loader from "../../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import CardHeader from "../../../../components/Card/CardHeader";
import { getUploadTimeTables } from "../../../../services/AppManagement/TimeTableService";
import Pagination from "../../../../components/Pagination/Pagination";

const UploadTimeTableList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [isLoader, setIsLoader] = useState(false);

  async function fetchTimeTable({ selected = 0 }) {
    const session_id = localStorage.getItem('session_id');
    const page = selected + 1;
    try {
      setIsLoader(true);
      const response = await getUploadTimeTables(session_id, page);
      console.log(response.data.data, "response list");
      if (response.status === 200) {
        setData(response.data.data);
        setLastPage(response.data.last_page);
        setCurrentPage(selected);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchTimeTable({ selected: 0 });
  }, []);

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="Upload Time Table List"
          icon={<FaBell />}
          button={permissions.includes('time_table.create') && <div>
            <Link to="/upload/timetable/create" className="btn btn-primary mx-2">Add UploadTimeTable</Link>
            <Link to="/timetable/list" className="btn btn-secondary">Back</Link>
          </div>
          }
        />
        <div className="card-body">
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <Table
                  cols={tableConstants(permissions)}
                  data={data}
                  hover={true}
                />
              )}
              <Pagination
                totalPage={lastPage}
                currentPage={currentPage}
                handlePageChange={(i) => fetchTimeTable(i)}
              ></Pagination>
            </div>}
        </div>
      </div>
    </div>
  );
};
export default UploadTimeTableList;
