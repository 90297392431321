import React from "react";
import { faEdit, faKey, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

export const tableConstants = (resetPasswordHandler, resetPasswordStatus, resetPasswordChangeHandler, resetPasswordUserId, permissions, page) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{(page - 1) * 50 + count++}</span>;
      },
    },
    {
      title: 'name',
      render: rowData => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: 'email',
      render: rowData => {
        return <span>{rowData.email}</span>;
      },
    },
    {
      title: 'role',
      render: rowData => {
        return <span>{rowData.role ? rowData.role.name : ""}</span>;
      },
    },
    {
      title: 'Reset Password',
      render: rowData => {
        return (
          <Form>
            {
              resetPasswordUserId === rowData.id ?
                <React.Fragment>
                  <Form.Group>
                    <input name="new_password"
                      id="new_password"
                      className="form-control"
                      placeholder="Reset Password"
                      type="password"
                      onChange={(e) => resetPasswordChangeHandler(e)} />
                  </Form.Group>
                  <Button variant="primary" className="mt-1"
                    onClick={e => resetPasswordHandler(e, rowData.id)}>Reset</Button>
                </React.Fragment>
                : <FontAwesomeIcon icon={faKey}
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => resetPasswordStatus(rowData.id)}>
                </FontAwesomeIcon>
            }
          </Form>
        );
      }
    }
  ];

  if (permissions.includes("teacher.subject.mapping.create") || permissions.includes("teacher.subject.mapping.update")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("publish.result.update") &&
              <React.Fragment>
                {rowData.is_mapping ? (
                  <Link to={`/teacher/mapping/update/${rowData.id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                ) : (
                  <Link to={`/teacher/mapping/create/${rowData.id}`}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </Link>
                )}
              </React.Fragment>
            }
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
