import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const DashboardGraph = ({ data }) => {
  const svgRef = useRef();
  const tooltipRef = useRef();
  const containerRef = useRef(); // Reference for the container to track scroll position

  useEffect(() => {
    // Set up dimensions and margins
    const margin = { top: 20, right: 30, bottom: 40, left: 40 };
    const barWidth = 50; // Width of each bar
    const width = data.length * barWidth; // Dynamic width based on the number of data points
    const height = 450 - margin.top - margin.bottom;

    // Remove any existing SVG elements (useful for re-render)
    d3.select(svgRef.current).selectAll('*').remove();

    // Create the SVG element
    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Set up the scales
    const xScale = d3.scaleBand()
      .domain(data.map(d => d.program_name))
      .range([0, width])
      .padding(0.2);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.student_count)])
      .range([height, 0]);

    // Add X-axis
    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("transform", "rotate(-45)") // Rotate labels if needed
      .style("text-anchor", "end");

    // Add Y-axis
    svg.append('g')
      .call(d3.axisLeft(yScale));

    // Create a tooltip div but keep it hidden initially
    const tooltip = d3.select(tooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("pointer-events", "none"); // Ensure the tooltip doesn't interfere with mouse events

    // Add the bars with mouse events for tooltip
    svg.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', d => xScale(d.program_name))
      .attr('y', d => yScale(d.student_count))
      .attr('width', xScale.bandwidth()-10)
      .attr('height', d => height - yScale(d.student_count))
      .attr('fill', 'steelblue')
      .on("mouseover", function (event, d) {
        tooltip.style("opacity", 1);
        tooltip.html(`Class: ${d.program_name}<br>Students: ${d.student_count}`);
      })
      .on("mousemove", function (event, d) {
        const tooltipWidth = tooltip.node().offsetWidth;
        const tooltipHeight = tooltip.node().offsetHeight;

        const containerX = containerRef.current.getBoundingClientRect().left;
        const scrollX = containerRef.current.scrollLeft;
        const mouseX = event.clientX + scrollX - containerX;

        // Calculate the initial tooltip position
        let left = mouseX - tooltipWidth / 2;
        let top = yScale(d.student_count) - tooltipHeight - 10;

        // Adjust position to keep tooltip within viewport
        const containerWidth = containerRef.current.offsetWidth;
        const containerScrollWidth = containerRef.current.scrollWidth;

        if (left < 0) {
          left = 10; // Adjust if it goes off the left edge
        } else if (left + tooltipWidth > containerWidth + scrollX) {
          left = containerWidth + scrollX - tooltipWidth - 10; // Adjust if it goes off the right edge
        }

        if (top < 0) {
          top = event.clientY + 10; // Adjust if it goes off the top edge
        }

        tooltip.style("left", `${left}px`)
          .style("top", `${top}px`);
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
      });

  }, [data]);

  return (
    <div ref={containerRef}
      style={{
        position: "relative",
        overflowX: 'scroll',
        overflowY: 'hidden',
        width: '100%'
      }}>
      <svg ref={svgRef}></svg>
      <div ref={tooltipRef}></div>
    </div>
  );
};

export default DashboardGraph;
