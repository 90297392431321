import React, { useEffect, useState } from 'react';
import { getPrograms } from '../../../services/ProgramServices';
import CardHeader from '../../../components/Card/CardHeader';
import AttendanceChart from './AttendanceChart';
import { getProgramWiseAttendanceCount } from '../../../services/AppManagement/AttendanceService';
import { getActivatedInActivatedParentAppUserCount } from '../../../services/StudentService';
import DashboardCustomCard from '../../dashboard/DashboardCustomCard';
import Input from '../../../components/Input/Input';
import Loader from '../../../components/Loader/Loader';

const AttendanceDashboard = () => {
  const session_id = localStorage.getItem('session_id');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [parentUsers, setParentAppUsers] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [program_ids, setProgramIds] = useState([]);
  const [data, setData] = useState([]);

  const fetchPrograms = async () => {
    const response = await getPrograms();
    const program_ids = response.data && response.data.map((program) => program.id);
    setProgramIds(program_ids);
    getData(program_ids, date);
  }

  const getData = async (program_ids, date) => {
    try {
      setIsLoader(true);
      const response = await getProgramWiseAttendanceCount(program_ids, session_id, date);
      if (response.status === 200) {
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  }

  const fetchParentAppUsers = async () => {
    try {
      const response = await getActivatedInActivatedParentAppUserCount(session_id);
      console.log("parent app", response);
      if (response.status === 200) {
        setParentAppUsers(response.data.data);
      }
    }
    catch (error) {
      console.error(error)
    }
  }

  const dateChangeHandler = (e) => {
    setIsLoader(true);
    setDate(e.target.value);
    getData(program_ids, e.target.value);
  }

  useEffect(() => {
    fetchPrograms();
    fetchParentAppUsers();
  }, []);

  return (
    <div className='card'>
      <CardHeader
        title='Attendance Dashboard'
        icon={<i className='fa fa-calendar-check-o'></i>}
        button={''}
      />
      <div className='card-body'>
        <div className="row mb-4">
          <DashboardCustomCard
            title="Parent App Users"
            cardColor="bg_success_gradient"
            iconColor="clr3"
            icon="fa-solid fa-user-graduate"
            count={parentUsers.activated} />
          <DashboardCustomCard
            title="Parent App Not Using"
            cardColor="bg_primary_gradient"
            icon="fa-solid fa-user-tie"
            iconColor="clr2"
            count={parentUsers.inactivated} />
          <DashboardCustomCard
            title="Total  Student"
            cardColor="bg_secondary_gradient"
            icon="fa-solid fa-user-tie"
            iconColor="clr2"
            count={parentUsers.inactivated + parentUsers.activated} />
        </div>
        {/*<h6>Today's attendance count</h6>*/}
        <div className='row'>
          <div className='col-md-4'>
            <Input
              type="date"
              name="date"
              label="Date"
              id='date'
              onChangeHandler={dateChangeHandler}
              value={date} />
          </div>
        </div>
        {isLoader ? <Loader /> :
          <AttendanceChart data={data} />
        }
      </div>
    </div>
  );
};

export default AttendanceDashboard;
