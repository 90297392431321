import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/image/logo.png';
import { navigation } from './_nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TheSidebar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [childActiveIndex, setChildActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setChildActiveIndex(null)
  };

  return (
    <div className="sidebar-main bg-white">
      <Link to="/" className="sidebar-brand" >
        <img src={logo} alt="Logo" />
        <span className="brand-title">Apeejay Education</span>
      </Link>
      <ul className="accordion accordion-flush sidebar-item-parent"
        id="accordionFlushExample">
        {navigation.map((item, index) => (
          <li className={`accordion-item bottom-border`} key={index}>
            <div className="accordion-header" id={`flush-heading${index}`}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {item.label}
                  </Tooltip>
                }>
                <span
                  // to={item.to}
                  className={`accordion-button ${activeIndex !== index ? 'collapsed' : ''}`}
                  type="button"
                  onClick={() => handleToggle(index)}
                  aria-expanded={activeIndex === index}
                  aria-controls={`flush-collapse${index}`}
                >
                  {item.name}
                </span>
              </OverlayTrigger>
            </div>
            <div
              id={`flush-collapse${index}`}
              className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
              aria-labelledby={`flush-heading${index}`}
              data-bs-parent="#accordionFlushExample"
            >
              {item.children && item.children.map((child, childIndex) => (
                <div className={`accordion-body bottom-border ${childIndex === childActiveIndex ? "active-sidebar-child-item" : ""}`} key={childIndex}>
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        {child.label}
                      </Tooltip>
                    }>
                    <Link to={child.to} onClick={() => setChildActiveIndex(childIndex)}>
                      <span className="show">{child.name}</span></Link>
                  </OverlayTrigger>
                </div>
              ))
              }
            </div>
          </li>
        ))
        }
      </ul>
    </div>
  );
};

export default TheSidebar;
