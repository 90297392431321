import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";
import Table from "../../components/Table/Table";
import { tableConstants } from "./assessmentTableConstant";
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import CardHeader from '../../components/Card/CardHeader';
import { getAssessmentLists, deleteAssessmentList } from '../../services/AssessmentListService';

const AssessmentList = () => {
  const [examsData, setExamsData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [permissions] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  const fetchExams = async (page = 1) => {
    setIsLoader(true);
    try {
      const examsResponse = await getAssessmentLists();
      if (examsResponse.status === 200) {
        const examsData = examsResponse.data.data;
        setExamsData(examsData);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  };
  useEffect(() => {
    fetchExams();
  }, []);

  const examDelete = async (id) => {
    try {
      const response = await deleteAssessmentList(id);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        fetchExams();
      }
    } catch (error) {
      // Handle error
      return error;
    }
  };

  return (
    <React.Fragment>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <CardHeader
            title="Assessments"
            icon={<FaClipboardList />}
            button={permissions.includes("assessment.list.create") && (
              <Link className='btn btn-primary' to="/assessment/create">
                Add Assessment
              </Link>
            )}
          />
          <div className="card-body evaluating-table">
            <Table
              cols={tableConstants(examDelete, permissions)}
              data={examsData}
              hoverable={true}
              striped={true}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AssessmentList;
