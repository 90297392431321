import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import Card from '../../components/Card/Card';
import ProgramForm from './ProgramForm';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { updateProgram, createProgram, getProgramById } from '../../services/ProgramServices';

const ProgramCreate = ({ fetchPrograms }) => {
  const [programData, setProgramData] = useState({
    name: '',
    error: [],
    disableSubmit: true,
    loading: false,
  });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getUniqueProgram();
    }
  }, []);

  const getUniqueProgram = async () => {
    try {
      const response = await getProgramById(params.id);
      if (response.status === 200) {
        const { id, name } = response.data.data;
        setProgramData({ ...programData, id, name, disableSubmit: true });
      }
    } catch (error) {
      console.error(error);
      setProgramData({ ...programData, disableSubmit: true });
    }
  };

  const handleChange = (event) => {
    setProgramData({ ...programData, name: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgramData({ ...programData, disableSubmit: false });

    const { name } = programData;
    const organization_id = localStorage.getItem('organization_id');
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user.id;
    const isEdit = !!params.id; // Check if it's an edit or create operation
    const data = { name, organization_id, user_id };

    try {
      const response = isEdit
        ? await updateProgram(params.id, data)
        : await createProgram(data);

      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        fetchPrograms();
        navigate('/program/list');
      }
    } catch (error) {
      console.error(error);
      setProgramData({ ...programData, disableSubmit: true });
    }
  };

  const back = () => {
    navigate('/program/list');
  };

  return (
    <Card componentName={programData.id ? 'Update Class' : 'Add Class'} back={back}>
      <ProgramForm submitHandler={handleSubmit}
        changeHandler={handleChange} data={programData} />
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrograms: () => dispatch(fetchPrograms()),
  };
};

export default connect(null, mapDispatchToProps)(ProgramCreate);
