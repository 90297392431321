import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import ExamFilter from "../Exam/ExamFilter";
import Table from "../../components/Table/Table";
import { tableConstants } from "./assessmentMarkingTableConstant";
import Pagination from "../../components/Pagination/Pagination";
import { getTeacherSubjectMapping } from "../../services/TeacherSubjectMappingService";
import { getExams, getFilteredExams, getProgramWiseExamList } from "../../services/ExamService";
import { getProgramWiseSubjectList } from "../../services/ExamService";

const AssessmentMarkingList = () => {
  const [programData, setProgramData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [examList, setExamList] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [last_page, setLastPage] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [program_id, setProgramID] = useState('');
  const [subject_id, setSubjectID] = useState('');
  const [evaluation_type_id, setEvaluationTypeID] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [name, setName] = useState('');
  const [term, setTerm] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const statusData = [
    { id: 1, name: "Not Graded" },
    { id: 2, name: "In Progress" },
    { id: 3, name: "Graded" },
  ];
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const session_id = localStorage.getItem("session_id");
  const dispatch = useDispatch();
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);

  useEffect(() => {
    dispatch(fetchEvaluationTypes());
    fetchTeacherSubjects();
    getEventList({ selected: 0 });
  }, [dispatch]);

  const fetchTeacherSubjects = async () => {
    try {
      const user_id = JSON.parse(localStorage.getItem("user")).id;
      const response = await getTeacherSubjectMapping(user_id, session_id);
      if (response.status === 200) {
        const programList = response.data.data.programs || [];
        const class_in_charge = response.data.data.class_in_charge || [];
        const programs = programList.map((item) => ({ id: item.id, subjects: [...item.subjects] }));
        const programData = [...programList, ...class_in_charge];

        const uniqueProgramData = Array.from(new Set(programData.map((item) => item.id)))
          .map((id) => programData.find((item) => item.id === id));
        setProgramData(uniqueProgramData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEventList = async ({ selected = 0 }) => {
    const page_number = selected + 1;
    try {
      setSearch(false);
      setIsLoader(true);
      setCurrentPage(page_number);
      const response = await getExams(session_id, page_number);
      if (response.status === 200) {
        const eventData = response.data.data;
        let last_page = response.data.last_page;
        setEventData(eventData);
        setLastPage(last_page);
      }
    } catch (error) {
      console.log("error", error);
    }
    finally {
      setIsLoader(false);
    }
  };

  const handleSearch = useCallback(async ({ selected }) => {
    const page = selected + 1;
    try {
      setSearch(true);
      setIsLoader(true);
      setCurrentPage(page);
      const response = await getFilteredExams(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const { data, last_page } = response.data;
        setEventData(data);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  }, [program_id, subject_id, evaluation_type_id, name, start, end, term, status, session_id])

  const handleReset = (event) => {
    event.preventDefault();
    setProgramID('');
    setSubjectID('');
    setName('');
    setEvaluationTypeID('');
    setStart('');
    setEnd('');
    setTerm('');
    setStatus('');
    getEventList({ selected: 0 });
  };

  const getSubjectList = async (id) => {
    try {
      const response = await getProgramWiseSubjectList(id);
      if (response.status === 200) {
        const subjectData = response.data.data;
        setSubjectData(subjectData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getExamList = async (program_id) => {
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        const examList = response.data.data;
        setExamList(examList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setAdvancedSearch(!advancedSearch)
  };// Add program_id and search to the dependencies

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setExamList([]);
      setSubjectData([]);
      setProgramID(value);
      getExamList(value);
      getSubjectList(value);
      setSearch(true); // Set search flag to true when program_id changes
    }
    else if (name === 'subject_id') {
      setSubjectID(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'evaluation_type_id') {
      setEvaluationTypeID(value);
    }
    else if (name === 'start') {
      setStart(value);
    }
    else if (name === 'end') {
      setEnd(value);
    }
    else if (name === 'term') {
      setTerm(value);
    }
    else if (name === 'status') {
      setStatus(value);
    }
  };

  return (
    <div className="card">
      <div className="event-list">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div className="page-title d-flex align-items-center">
              <Button className="btn-success" onClick={advancedSearchHandler}>
                <FaSearch /> Search
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className={`filter-event ${advancedSearch ? "show" : "hide"} mb-3`} id="advanced-search">
            <ExamFilter
              evaluationType={evaluationType}
              programData={programData}
              subjectData={subjectData}
              examList={examList}
              subject_id={subject_id}
              program_id={program_id}
              name={name}
              start={start}
              end={end}
              term={term}
              status={status}
              onChangeHandler={changeHandler}
              statusData={statusData}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          </div>
          {isLoader ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="evaluating-table event-tablecus">
              {eventData && eventData.length > 0 ? (
                <Table cols={tableConstants(permissions, current_page)}
                  data={eventData}
                  hoverable={true} striped={true} />
              ) : (
                <div className="d-flex justify-content-center">
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {eventData && eventData.length > 0 && (
        <div className="card-footer">
          <Pagination
            totalPage={last_page}
            currentPage={current_page - 1}
            handlePageChange={(i) => search ? handleSearch(i) : getEventList(i)}
          />
        </div>
      )}
    </div>
  );
};

export default AssessmentMarkingList;
