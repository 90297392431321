import React from 'react';
import { Form, Button } from 'react-bootstrap';
import './ExcelBulkUpload.css';
import Loader from '../../components/Loader/Loader';
import Input from '../../components/Input/Input';

const ExcelBulkUpload = ({
  downloadExcel,
  excelFileReader,
  bulkUploadFile,
  loader,
}) => {
  return (
    <Form.Group>
      <div className="download-excel">
        <span className="download-excel-text">Don't have the sample excel? Click Here*</span>
        <Button type="submit" className='mx-2' onClick={downloadExcel} variant="success">
          Download Excel
        </Button>
      </div>
      {loader ? <div className="loader"><Loader /></div> : ''}
      <div className="upload-container">
        <h4 className='text-align-center'>Upload Excel Files</h4>
        <div className="file-upload">
          <Input
            type="file"
            name='file'
            className='form-control'
            onChangeHandler={e => excelFileReader(e)}
            multiple accept=".xlsx, .xls"
          />
        </div>
        <Button type="button" onClick={bulkUploadFile}
          variant="success">Submit Excel</Button>
      </div>
    </Form.Group>
  );
};
export default ExcelBulkUpload;
