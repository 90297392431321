import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import { tableConstants } from "./examTableConstant";
import Table from "../../components/Table/Table";
import './Exam.css';
import {
  getExams, getFilteredExams, deleteExam, updateExamEndDate,
  getProgramWiseSubjectList, getProgramWiseExamList,
  examActiveInActive
} from "../../services/ExamService";
import { enqueueSnackbar } from "notistack";
import Pagination from "../../components/Pagination/Pagination";
import ExamFilter from "./ExamFilter";
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Input/Input";

const EventList = () => {
  const [subjectData, setSubjectData] = useState([]);
  const [examList, setExamList] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [last_page, setLastPage] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [program_id, setProgramID] = useState('');
  const [subject_id, setSubjectID] = useState('');
  const [evaluation_type_id, setEvaluationTypeID] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [name, setName] = useState('');
  const [term, setTerm] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const [exam_ids, setExamIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const statusData = [
    { id: 1, name: "Not Graded" },
    { id: 2, name: "In Progress" },
    { id: 3, name: "Graded" },
  ];
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const session_id = localStorage.getItem("session_id");
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);

  useEffect(() => {
    const org_id = localStorage.getItem("organization_id");
    if (!org_id || !session_id) return;

    dispatch(fetchPrograms());
    dispatch(fetchEvaluationTypes());
    getEventList({ selected: 0 });
  }, [dispatch]);

  const getEventList = async ({ selected }) => {
    let current_page = selected + 1;
    try {
      setSearch(false);
      setIsLoader(true);
      setCurrentPage(current_page);
      const response = await getExams(session_id, current_page);
      if (response.status === 200) {
        const eventData = response.data.data;
        let last_page = response.data.last_page;
        setEventData(eventData);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  };

  const getSubjectList = async (id) => {
    try {
      const response = await getProgramWiseSubjectList(id);
      if (response.status === 200) {
        const subjectData = response.data.data;
        setSubjectData(subjectData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = useCallback(async ({ selected }) => {
    const page = selected + 1;
    try {
      setSearch(true);
      setIsLoader(true);
      setCurrentPage(page);
      const response = await getFilteredExams(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const { data, last_page } = response.data;
        setEventData(data);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  }, [program_id, subject_id, evaluation_type_id, name, start, end, term, status, session_id])

  // <--- Make sure the dependencies are correctly listed

  const handleReset = (event) => {
    event.preventDefault();
    setProgramID('');
    setSubjectID('');
    setName('');
    setEvaluationTypeID('');
    setStart('');
    setEnd('');
    setTerm('');
    setStatus('');
    getEventList({ selected: 0 });
  };

  const getExamList = async (program_id) => {
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        const examList = response.data.data;
        setExamList(examList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      const response = await deleteExam(id);
      if (response.status === 200) {
        enqueueSnackbar("Exam deleted successfully", { variant: "success" });
        getEventList({ selected: 0 });
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setAdvancedSearch(!advancedSearch)
  };// Add program_id and search to the dependencies

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setExamList([]);
      setSubjectData([]);
      setProgramID(value);
      getExamList(value);
      getSubjectList(value);
      setSearch(true); // Set search flag to true when program_id changes
    }
    else if (name === 'subject_id') {
      setSubjectID(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'evaluation_type_id') {
      setEvaluationTypeID(value);
    }
    else if (name === 'start') {
      setStart(value);
    }
    else if (name === 'end') {
      setEnd(value);
    }
    else if (name === 'term') {
      setTerm(value);
    }
    else if (name === 'status') {
      setStatus(value);
    }
  };

  const eventHideAndShowHandler = async (e, item) => {
    try {
      let is_active = e.target.checked ? 1 : 0;
      const data = { is_active, event_id: item.id };
      const response = await examActiveInActive(data);
      if (response.status === 200) {
        enqueueSnackbar("Exam status updated successfully", { variant: "success" });
        getEventList({ selected: 0 });
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const selectDateChangeHandler = (e, item) => {
    if (e.target.checked) {
      setExamIds([...exam_ids, item.id]);
    }
    else {
      setExamIds(exam_ids.filter(id => id !== item.id));
    }
  }

  const selectAllExamDateChangeHandler = (e) => {
    if (e.target.checked) {
      setExamIds(eventData.map(item => item.id));
    }
    else {
      setExamIds([]);
    }
  }

  const endDateUpdateHandler = async () => {
    try {
      const data = { exam_ids, end };
      const response = await updateExamEndDate(data);
      if (response.status === 200) {
        setShowModal(false);
        enqueueSnackbar("Exam End Date updated successfully", { variant: "success" });
        getEventList({ selected: 0 });
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
    finally {
      setExamIds([]);
      setEnd('');
    }
  }

  return (
    <div className="card">
      <div className="">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div className="page-title d-flex align-items-center">
              <Button className="btn-success" onClick={advancedSearchHandler}>
                <FaSearch /> Search
              </Button>
            </div>
            <div>
              {permissions && permissions.includes("bulk.exams.create") && (
                <Link className="btn btn-primary mx-2" to="/bulk/exam/create">
                  Bulk Exam Create
                </Link>
              )}
              {permissions && permissions.includes("exams.create") && (
                <Link className="btn btn-primary" to="/exam/create">
                  Add
                </Link>
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="End Date Update"
          size="lg"
        >
          <div className="modal-body">
            <div className="row">
              <div className="col-md-10">
                <div className="form-group">
                  <Input
                    type="date"
                    label="End Date"
                    className="form-control"
                    name="end"
                    onChange={e => setEnd(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer mt-2">
            <Button
              variant="secondary" className="mx-2"
              onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={endDateUpdateHandler}>
              Save
            </Button>
          </div>
        </Modal>

        <div className="card-body">
          <div className={`filter-event ${advancedSearch ? 'show' : 'hide'} mb-5`} id="advanced-search">
            <ExamFilter
              evaluationType={evaluationType}
              programData={programData}
              subjectData={subjectData}
              examList={examList}
              subject_id={subject_id}
              program_id={program_id}
              name={name}
              start={start}
              end={end}
              term={term}
              status={status}
              onChangeHandler={changeHandler}
              statusData={statusData}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          </div>

          {isLoader ? <Loader /> : <React.Fragment>
            {eventData && eventData.length > 0 ? (
              <div className="evaluating-table event-tablecus">
                <Table
                  cols={tableConstants(deleteEvent, selectDateChangeHandler, selectAllExamDateChangeHandler, exam_ids, eventHideAndShowHandler, permissions, current_page)}
                  data={eventData}
                  hoverable={true}
                  striped={true}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center"> No data found</div>
            )}
          </React.Fragment>}
        </div>
        {
          permissions && (permissions.includes("exams.update") || permissions.includes("exams.create")) &&
          <div className="d-flex justify-content-end">
            <Button className="btn btn-primary mx-3"
              onClick={() => setShowModal(true)}>
              Update EndDate
            </Button>
          </div>
        }
      </div>
      {eventData && eventData.length > 0 && (
        <div className="card-footer">
          <Pagination
            totalPage={last_page}
            currentPage={current_page - 1}
            handlePageChange={(i) => search ? handleSearch(i) : getEventList(i)}
          />
        </div>
      )}
    </div>
  );
};

export default EventList;
