import React from 'react';
import { Form } from 'react-bootstrap';
import Loader from '../../../components/Loader/Loader';
import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const AttendenceForm = ({ submitHandler, changeHandler, attendanceMarkHandler, attendanceMarkHandlerSingle, fetchAttendance,
  programData, errorMessage, attendance_date, studentAttendance, program_id, isLoader, attendenceLabelList }) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-6 pb-mb-2">
          <Input
            type="date"
            name="attendance_date"
            value={attendance_date}
            onChangeHandler={changeHandler}
            error={errorMessage}
            required={true}
          />
        </div>
        <div className="col-md-6 pb-mb-2">
          <Select
            name="program_id"
            placeHolder={'Select Class'}
            value={program_id}
            onChangeHandler={changeHandler}
            options={programData}
          />
        </div>
      </div>
      {isLoader ? <Loader /> : <div>
        {studentAttendance && studentAttendance.length > 0 ? (
          <div className="row mt-3 p-3">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>RollNo</th>
                  <th>IDNumber</th>
                  <th>Name</th>
                  <th>
                    <div className="col-md-6">
                      <Select
                        onChangeHandler={(e) => attendanceMarkHandler(e)}
                        name="attendence" id="attendence"
                        placeHolder={'Select All'}
                        options={attendenceLabelList}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentAttendance.map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item && item.student ? item.student.student_program.roll_no : ""}</td>
                    <td>{item && item.student ? item.student.id_number : ""}</td>
                    <td>{item && item.student ? item.student.first_name + " " + item.student.last_name : ""}</td>
                    <td>
                      <div className="col-md-6">
                        <Select
                          onChangeHandler={(e) => attendanceMarkHandlerSingle(e, idx)}
                          name={`att-${idx}`}
                          value={item ? item.att_label : ""}
                          // disabled={item.att_label == 3 ? true : false}
                          placeHolder={'Select Attendance'}
                          options={attendenceLabelList}
                        />
                      </div>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
            {isLoader && (<Loader />)}
            <div className="d-flex justify-content-end">
              <div className="mt-2">
                <Button
                  variant="success"
                  disabled={isLoader}
                  type='submit'
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>}
    </Form>
  );
};

export default AttendenceForm;
