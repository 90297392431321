import React from "react";

export const tableConstants = () => {
  var count = 1;
  let formatedData = [
    {
      title: 'SrNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{`${rowData.first_name} ${rowData.last_name}`}</span>;
      },
    },
    {
      title: 'Roll NO',
      render: (rowData) => {
        return <span>{rowData.roll_no}</span>;
      },
    },
    {
      title: 'Admission NO',
      render: rowData => {
        return <span>{rowData.id_number}</span>;
      },
    }
  ];
  return formatedData;
};
