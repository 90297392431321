import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { fetchSubjects } from '../../redux/subject/subjectActions';
import { fetchPrograms } from '../../redux/program/programActions';
import Card from '../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { createSubject, updateSubject, getSubjectById } from '../../services/SubjectServices';
import SubjectForm from './SubjectForm';
import Loader from '../../components/Loader/Loader';

const SubjectCreate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const programData = useSelector(state => state.program.programs.data);
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false);
  const [programIds, setProgramIds] = useState([]);

  useEffect(() => {
    dispatch(fetchPrograms());
    dispatch(fetchSubjects({ selected: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      getUniqueSubject();
    }
  }, [id]);

  const getUniqueSubject = async () => {
    try {
      setLoader(true);
      const response = await getSubjectById(id);
      if (response.status === 200) {
        const { id, name, programs } = response.data.data;
        const programIds = programs.map(item => item.id.toString());
        setName(name);
        setProgramIds(programIds);
      }
    } catch (error) {
      // Handle error
      return error;
    }
    finally {
      setLoader(false);
    }
  };

  const handleInputChange = (event) => {
    const progId = event.target.value;
    let updatedProgramIds;

    if (event.target.checked) {
      updatedProgramIds = [...programIds, progId];
    } else {
      updatedProgramIds = programIds.filter(id => id !== progId);
    }

    setProgramIds(updatedProgramIds);
  };

  const handleSwitchChange = (event) => {
    const updatedProgramIds = event.target.checked
      ? programData.map(program => program.id.toString())
      : [];
    setProgramIds(updatedProgramIds);
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const organizationId = localStorage.getItem('organization_id');
    const sessionId = localStorage.getItem('session_id');

    if (!organizationId) {
      let msg = 'Please select any organization';
      enqueueSnackbar(msg, { variant: 'error' });
      return;
    }
    if (!sessionId) {
      let msg = 'Please select any session';
      enqueueSnackbar(msg, { variant: 'error' });
      return;
    }
    if (programIds.length === 0) {
      let msg = 'Please select any class';
      enqueueSnackbar(msg, { variant: 'error' });
      return;
    }
    if (!name) {
      let msg = 'Please enter subject name';
      enqueueSnackbar(msg, { variant: 'error' });
      return;
    }

    try {
      const requestData = {
        name,
        organization_id: organizationId,
        session_id: sessionId,
        program_ids: programIds,
      };

      setLoader(true);
      let response = id ? await updateSubject(id, requestData) :
        await createSubject(requestData);

      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        dispatch(fetchSubjects({ selected: 0 }));
        navigate('/subject/list');
      }
    } catch (error) {
      return error;
    }
    finally {
      setLoader(false);
    }
  };

  const goBack = () => {
    navigate('/subject/list');
  };

  if (!programData || programData.length === 0) {
    return (<Loader />);
  }

  return (
    <Card
      componentName={id ? "Update Scholastic Subjects" : "Add Scholastic Subjects"}
      back={goBack}>
      <SubjectForm
        name={name}
        programData={programData}
        programIds={programIds}
        loader={loader}
        onInputChange={handleInputChange}
        onSwitchChange={handleSwitchChange}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    programData: state.program.programs.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubjects: (currentPage) => dispatch(fetchSubjects(currentPage)),
    fetchPrograms: () => dispatch(fetchPrograms()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectCreate);
