import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import { Button } from 'react-bootstrap';
import { getClassWiseStudentList } from '../../../services/StudentService';
import { getClassWiseSubjects } from '../../../services/SubjectServices';
import { createStudentSubjectsEnrollment, getStudentEnrolledSubjects } from '../../../services/StudentSubjectMappingService';
import StudentSubjectEnrollForm from './StudentSubjectEnrollForm';

const StudentSubjectEnrollManage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [program_id, setProgramId] = useState("");
  const [student_id, setStudentId] = useState('');
  const [SubjectList, setSubjectList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [student_loader, setStudentLoader] = useState(false);
  const [subject_ids, setSubjectIds] = useState([]);
  const pathname = window.location.pathname;

  const getSubjectList = async (programId) => {
    try {
      const res = await getClassWiseSubjects(programId);
      const subjectData = res.data.data;
      setSubjectList(subjectData);
    } catch (error) {
      console.log(error);
    }
  };

  const getStudentList = async (program_id) => {
    try {
      setStudentList([]);
      setStudentLoader(true);
      const response = await getClassWiseStudentList(program_id);
      if (response.status === 200) {
        const studentList = response.data.data.map((student) => {
          return { id: student.id.toString(), name: student.first_name + " " + student.last_name }
        });
        setStudentList(studentList);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setStudentLoader(false);
    }
  };

  const fetchStudentMappedSubjects = async (student_id) => {
    try {
      setLoader(true);
      setSubjectIds([]);
      const response = await getStudentEnrolledSubjects(student_id, program_id);
      if (response.status === 200) {
        const subjectIDS = response.data.data.map((subject) => subject.subject_id.toString());
        setSubjectIds(subjectIDS);
      }
    }
    catch (error) {
      console.log("Error", error);
    }
    finally {
      setLoader(false);
    }
  }

  const studentChangeHandler = (event) => {
    const student_id = event.target.value;
    setStudentId(student_id);
    fetchStudentMappedSubjects(student_id);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "program_id") {
      setProgramId(value);
      getStudentList(value);
      getSubjectList(value);
    }
  };

  const subjectChangeHandler = (event) => {
    const subject_id = event.target.value;
    let updatedSubjectIds;

    if (event.target.checked) {
      updatedSubjectIds = [...subject_ids, subject_id];
    } else {
      updatedSubjectIds = subject_ids.filter(id => id !== subject_id);
    }
    setSubjectIds(updatedSubjectIds);
  };

  const selectAllSubjectHandler = (event) => {
    const updatedSubjectIds = event.target.checked
      ? SubjectList.map(subject => subject.id.toString())
      : [];
    setSubjectIds(updatedSubjectIds);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem("session_id");
    const data = { session_id, program_id, student_id, subject_ids };
    try {
      setLoader(true);
      const response = await createStudentSubjectsEnrollment(data);
      setLoader(false);
      if (response.status === 201) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        navigate("/student/subject/enroll");
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const back = () => {
    navigate("/student/subject/mapping/list");
  };

  return (
    <div className='card'>
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="main-title-page page-title d-flex align-items-center">
            <div className="mx-2">
            </div>
            <span>{pathname
              === "/student/subject/mapping/update" ?
              "Update Student Subject Mapping" : "Student Subject Mapping"
            }</span>
          </div>
          <Button variant="secondary" className='mx-2'
            onClick={back}>
            Back</Button>
        </div>
      </div>
      {loader ? <Loader /> : null}
      <div className="card-body">
        <StudentSubjectEnrollForm
          student_loader={student_loader}
          program_id={program_id}
          student_id={student_id}
          subject_ids={subject_ids}
          studentList={studentList}
          subjectData={SubjectList}
          submitHandler={handleSubmit}
          changeHandler={handleChange}
          subjectChangeHandler={subjectChangeHandler}
          selectAllSubjectHandler={selectAllSubjectHandler}
          studentChangeHandler={studentChangeHandler}
        />
      </div>
    </div>
  );
};

export default StudentSubjectEnrollManage;


