import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getSessions = async () => {
  try {
    const response = await axios.get(ENDPOINTS.SESSION.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getSessionById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.SESSION.GET_SESSION}/${id}/edit`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createSession = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.SESSION.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSession = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.SESSION.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSession = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.SESSION.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
