import axios from 'axios';
import { ENDPOINTS } from '../endpoints';

export const getAttendance = async (program_id, session_id, att_date) => {
  try {
    const response = await axios.get(ENDPOINTS.ATTENDANCE.GET, {
      params: {
        data: {
          program_id: program_id,
          session_id: session_id,
          att_date: att_date
        }
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const createAttendanceMarking = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ATTENDANCE.CREATE, data);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const updateAttendanceMarking = async (data) => {
  try {
    const response = await axios.put(ENDPOINTS.ATTENDANCE.UPDATE, data);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getMappedPrograms = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.ATTENDANCE.GET_MAPPED_PROGRAMS}/${id}`);
    return response;
  }
  catch (error) {
    throw error;
  }
};

export const createAttendanceTeacherMapping = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ATTENDANCE.CREATE_ATTENDANCE_TEACHER_MAPPING, { data });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getAttendanceLabelList = async () => {
  try {
    const response = await axios.get(ENDPOINTS.ATTENDANCE.GET_ATTENDANCE_LABEL_LIST);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const bulkUploadAttendanceMarking = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ATTENDANCE.BULK_UPLOAD, { data });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getAttendanceReport = async (data) => {
  try {
    const response = await axios.get(ENDPOINTS.ATTENDANCE.GET_ATTENDANCE_REPORT, {
      params: {
        data: data
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getProgramWiseAttendanceCount = async (program_ids, session_id, date) => {
  try {
    const response = await axios.get(ENDPOINTS.ATTENDANCE.GET_PROGRAM_WISE_ATTENDANCE_COUNT, {
      params: {
        data: {
          program_ids: program_ids,
          session_id: session_id,
          date: date,
        }
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}
