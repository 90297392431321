import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getEvaluationTypes = async () => {
  try {
    const response = await axios.get(ENDPOINTS.EVALUATION_TYPE.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getEvaluationTypeById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EVALUATION_TYPE.GET_EVALUATION_TYPE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createEvaluationType = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.EVALUATION_TYPE.CREATE, {data});
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEvaluationType = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.EVALUATION_TYPE.UPDATE}/${id}`, {data});
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteEvaluationType = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.EVALUATION_TYPE.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
