import React, { useEffect, useState } from 'react';
import { fetchPrograms } from '../../../redux/program/programActions';
import { useSelector, useDispatch } from 'react-redux';
import { FaKey } from "react-icons/fa";
import Table from '../../../components/Table/Table';
import Loader from '../../../components/Loader/Loader';
import Pagination from '../../../components/Pagination/Pagination';
import { tableConstants } from './ActivationKeyTableConstant';
import { enqueueSnackbar } from 'notistack';
import {
  getStudents, getFilteredStudents, generateActivationKey
} from '../../../services/StudentService';
import CardHeader from '../../../components/Card/CardHeader';
import StudentFilter from '../../Student/StudentFilter';

const StudentActivationKey = () => {
  const [name, setName] = useState('');
  const [id_number, setIdNumber] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [program_id, setProgramId] = useState('');
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const { programs } = useSelector((state) => state.program);
  const programList = programs.data;
  const session_id = localStorage.getItem("session_id");

  const searchHandler = async () => {
    try {
      setLoader(true);
      setStudentList([]);
      const response = await getFilteredStudents(session_id, name, id_number, program_id);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
        setLastPage(data.last_page);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  const getStudentList = async ({ selected }) => {
    let page = selected + 1;
    try {
      setLoader(true);
      const response = await getStudents(page, session_id);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
        setLastPage(data.last_page);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getStudentList({ selected: 0 });
    dispatch(fetchPrograms({ selected: 0 }));
  }, []);

  const resetHandler = async () => {
    setName('');
    setIdNumber('');
    setProgramId('');
    getStudentList({ selected: 0 });
  }

  const generateKeyHandler = async () => {
    try {
      setLoader(true);
      const response = await getFilteredStudents(session_id, name, id_number, program_id);
      const students = response.data.data.filter(student => student.activation_key ? "" : student.id);
      const studentIds = students.map(student => student.id);
      if (studentIds.length === 0) {
        enqueueSnackbar("All students have activation key", { variant: "error" });
        return;
      }
      const data = { student_ids: studentIds, session_id };
      const response1 = await generateActivationKey(data);
      if (response1.status === 200) {
        const data = response1.data;
        const res = await getFilteredStudents(session_id, name, id_number, program_id);
        if (res.status === 200) {
          const data = res.data;
          setStudentList(data.data);
          setLastPage(data.last_page);
        }
        enqueueSnackbar(data.message.success, { variant: "success" });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }
  const generateActivationKeySingleStudent = async (rowData) => {
    if (rowData.suspended) {
      enqueueSnackbar("Student has been suspended.", { variant: "error" });
      return;
    }
    const data = { student_ids: [rowData.id], session_id };
    setIdNumber(rowData.id_number)
    try {
      setIsLoader(true);
      const response = await generateActivationKey(data);
      if (response.status === 200) {
        const studentRes = await getStudents(1, session_id);
        if (studentRes.status === 200) {
          const data = studentRes.data;
          setStudentList(data.data);
          setLastPage(data.last_page);
        }
        enqueueSnackbar("Activation key generated successfully", { variant: "success" });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoader(false);
    }
  }
  const classChangeHandler = (e) => {
    setProgramId(e.target.value);
  }

  return (
    <div className='card'>
      {loader ? (<Loader />) :
        (<div className="program-list">
          <CardHeader
            title="Activation Key Generate"
            icon={<FaKey />}
            button={''}
          />
          <div className="card-body">
            <StudentFilter
              type="activation"
              programList={programList}
              name={name}
              id_number={id_number}
              program_id={program_id}
              setName={setName}
              setIdNumber={setIdNumber}
              classChangeHandler={classChangeHandler}
              searchHandler={searchHandler}
              resetHandler={resetHandler}
              generateKeyHandler={generateKeyHandler}
            />
            <div className='evaluating-table mt-2'>
              {studentList && studentList.length > 0 ?
                <Table
                  cols={tableConstants(generateActivationKeySingleStudent, isLoader, id_number)}
                  data={studentList}
                  striped={true}
                  hoverable={true}
                /> : 'No data found'}
            </div>
          </div>
        </div>
        )}
      <div className="card-footer">
        {studentList && studentList.length ? (
          <Pagination
            totalPage={lastPage}
            handlePageChange={(i) => getStudentList(i)}
          ></Pagination>)
          : null}
      </div>
    </div>
  );
};
export default StudentActivationKey;
