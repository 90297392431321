import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchScoreSchemes } from "../../redux/scoreSchemes/scoreSchemesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaTachometerAlt } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import CardHeader from "../../components/Card/CardHeader";
import { deleteScoreScheme } from "../../services/ScoreSchemeService";

function ScoreSchemesList() {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const dispatch = useDispatch();
  const scoreSchemesData = useSelector((state) => state.scoreScheme.scoreSchemes.data);
  const lastPage = useSelector((state) => state.scoreScheme.scoreSchemes.last_page);


  useEffect(() => {
    const org_id = localStorage.getItem("organization_id");
    if (!org_id) {
      enqueueSnackbar("Please select any Organization", { variant: 'error' });
    } else {
      dispatch(fetchScoreSchemes({ selected: 0 }));
    }
  }, []);

  const deleteScheme = async (id) => {
    try {
      const response = await deleteScoreScheme(id);
      console.log(response, "delete response");
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        dispatch(fetchScoreSchemes({ selected: 0 }));
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const renderSchemeCards = () => {
    return scoreSchemesData && scoreSchemesData.map((item, idx) => (
      <div className="col-md-6 mb-4" key={idx}>
        <div className="card-wrap border-gradient-green p-3" text="white">
          <div className="">
            <div className="row">
              <h5 className="card-title col-md-10">{item.name}</h5>
              <div className="col-md-2 text-right">
                {permissions.includes("scoreschemes.update") && (
                  <Link to={`/score/schemes/update/${item.id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                )}
                &nbsp;&nbsp;
                {permissions.includes("scoreschemes.delete") && (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        )
                      )
                        deleteScheme(item.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </span>
                )}
              </div>
            </div>
            <p>{item.type}</p>
            <div className="card-text text-light">
              {item.ScoreOptions.map((option, index) => (
                <Badge
                  key={index}
                  className="mr-1"
                  style={{
                    backgroundColor: option.color,
                    padding: 10,
                    borderRadius: 20,
                  }}
                  shape="pill"
                >
                  {option.option_name}
                </Badge>
              ))}{" "}
              {!item.ScoreOptions[0] ? <p className="text-dark">NA</p> : null}
            </div>
            {item.updated_at ? (
              <p className="card-text text-center">
                <small className="text-muted">
                  Last updated {item.updated_at}
                </small>
              </p>
            ) : (
              <p className="card-text">
                <small className="text-muted">
                  Created At: {item.created_at}
                </small>
              </p>
            )}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="card">
      {!scoreSchemesData ? (
        <Loader />
      ) : (
        <div className="scoreschema-list">
          <div className="animated">
            {scoreSchemesData.length ? (
              <div className="card">
                <CardHeader
                  title="Score schemes"
                  icon={<FaTachometerAlt />}
                  button={
                    permissions.includes("scoreschemes.create") && (
                      <Link className="btn btn-primary" to="/score/schemes/create">
                        Add Score Scheme
                      </Link>
                    )
                  }
                />
              </div>
            ) : null}
            <div className="card-body row d-flex justify-content-between flex-wrap">
              {renderSchemeCards()}
            </div>
            {scoreSchemesData.length === 0 ? (
              <div className="card-header">
                <Link style={{ color: "#FFFFFF" }} to="/score/schemes/create">
                  <button className="btn-primary" style={{ float: "right" }}>
                    Add
                  </button>
                </Link>
                <br></br>
                <br></br>
              </div>
            ) : null}
            {scoreSchemesData.length ? (
              <Pagination
                totalPage={lastPage}
                handlePageChange={(i) => fetchScoreSchemes(i)}
              ></Pagination>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
export default ScoreSchemesList;
