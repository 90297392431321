import React, { useState, useEffect } from "react";
import { FaGoodreads } from "react-icons/fa";
import { tableConstants } from "./WordOfTheDayTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import Modal from "../../../components/Modal/Modal";
import { enqueueSnackbar } from "notistack";
import WordOfTheDayForm from "./WordOfTheDayForm";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import WordOfTheDayBulkUploadForm from "./WordOfTheDayBulkUploadForm";
import * as XLSX from 'xlsx';
import WordOfTheDaySample from './WordOfTheDaySample';
import {
  getWordOfTheDays, createWordOfTheDay,
  updateWordOfTheDay, deleteWordOfTheDay,
  bulkUploadWordOfTheDay
} from "../../../services/AppManagement/WordOfTheDayService";

const WordOfTheDayList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitile] = useState('');
  const [description, setDescription] = useState('');
  const [quote, setQuote] = useState('');
  const [word_date, setWordDate] = useState('');
  const [id, setId] = useState(null);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [isBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);

  const openModal = () => { setIsModalOpen(true) };
  const closeModal = () => {
    setIsBulk(false);
    setIsModalOpen(false)
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'title') {
      setTitile(value);
    }
    else if (name === 'quote') {
      setQuote(value);
    }
    else if (name === 'word_date') {
      setWordDate(value);
    }
  }
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  async function fetchWordOfTheDay({ selected = 0 }) {
    try {
      setIsLoader(true);
      setCurrentPage(selected);
      let page = selected + 1;
      const response = await getWordOfTheDays(page);
      if (response.status === 200) {
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchWordOfTheDay({ selected: 0 });
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      title: title,
      quote: quote,
      word_date: word_date,
      description: description
    };

    try {
      setIsLoader(true);
      let response;
      if (id) {
        data.id = id;
        data.status = 1;
        response = await updateWordOfTheDay(id, data);
      } else {
        response = await createWordOfTheDay(data);
      }
      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        closeModal();
        fetchWordOfTheDay({ selected: currentPage });
      }
      else {
        enqueueSnackbar(response.data.message.error, { variant: 'error' });
      }
    } catch (error) {
      console.error("API Error:", error.response);
      enqueueSnackbar("Something went wrong", { variant: 'error' });
    }
    finally {
      setIsLoader(false);
    }
  };

  const showModel = (rowData) => {
    openModal(true);
    setId(rowData.id);
    setTitile(rowData.title);
    setQuote(rowData.quote);
    setDescription(rowData.description);
    setWordDate(rowData.word_date);
  }
  const AddWordOfTheDayHandler = () => {
    openModal(true);
    setTitile('');
    setQuote('');
    setWordDate('');
    setDescription('');
    setId(null);
  }

  const bulkUploadHandler = () => {
    setIsBulk(true);
    openModal(true);
    setTitile('');
    setQuote('');
    setWordDate('');
    setDescription('');
    setId(null);
  }


  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(WordOfTheDaySample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `word-of-the-day.xlsx`);
  }

  const excelFileReader = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const result = {};
      workbook.SheetNames.forEach(sheet => {
        const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        result[sheet] = json_data;
      });

      // Now 'result' contains JSON data for each sheet
      let final_data = result['Sheet1'];

      // Now 'result' contains JSON data for each sheet
      let finalData = final_data && final_data.map((item) => {

        return item;
      });
      setFile(finalData);
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  const wordOfTheDaybulkUpload = async () => {
    if (file === null || file === undefined) {
      enqueueSnackbar("Please select file", { variant: "error" });
      return;
    }

    let data = { file };
    try {
      setIsLoader(true);
      const response = await bulkUploadWordOfTheDay(data);
      if (response && response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        closeModal();
        fetchWordOfTheDay({ selected: currentPage });
      }
      else {
        enqueueSnackbar(response.data.message.errors, { variant: "error" });
      }
    } catch (error) {
      return error;
    }
    finally {
      setIsLoader(false);
    }
  }

  const wordOfTheDayDelete = async (id) => {
    try {
      const response = await deleteWordOfTheDay(id);
      if (response.status === 200) {
        enqueueSnackbar("Word of The Day Deleted Successfully", { variant: "success" });
        fetchWordOfTheDay({ selected: currentPage });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  }

  return (
    <div className="card">
      <CardHeader
        title="Word of The Day"
        icon={<FaGoodreads />}
        button={permissions && permissions.includes('word_of_the_day.create') &&
          <React.Fragment>
            <button className="btn btn-primary mx-2"
              onClick={bulkUploadHandler}>Bulk Upload</button>
            <button className="btn btn-primary"
              onClick={AddWordOfTheDayHandler}>Add</button>
          </React.Fragment>
        }
      />
      <div className="card-body">
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h5>{id ? "Update Word of The Day" : "Add Word of The Day"}</h5>
          {isBulk ?
            <WordOfTheDayBulkUploadForm
              excelFileReader={excelFileReader}
              bulkUpload={wordOfTheDaybulkUpload}
              downloadExcel={downloadExcel}
              loader={isLoader}
            /> :
            <WordOfTheDayForm
              changeHandler={changeHandler}
              submitHandler={submitHandler}
              handleEditorChange={handleEditorChange}
              title={title}
              quote={quote}
              word_date={word_date}
              description={description}
            />
          }
        </Modal>
        {isLoader ? <Loader /> :
          <div className="row mt-3">
            {data && (
              <React.Fragment>
                <Table
                  cols={tableConstants(permissions, showModel, wordOfTheDayDelete, currentPage)}
                  data={data}
                  hover={true}
                />
                <Pagination
                  totalPage={lastPage}
                  currentPage={currentPage}
                  handlePageChange={(i) => fetchWordOfTheDay(i)}
                ></Pagination>
              </React.Fragment>
            )}
          </div>}
      </div>
    </div>
  );
};
export default WordOfTheDayList;
