import React from 'react';

const DashboardCustomCard = ({ title, cardColor, icon, iconColor, count }) => {
  return (
    <div className="col-xl-4 col-lg-6 pb-mb-2">
      <div className={`card report_card ${cardColor} p-4 mb-0 txt-white`}>
        <h5 className="reportcard_title">{title}</h5>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="reportcard_number">{count}</h6>
          <div className={`reportcard_icon ${iconColor}`}>
            <i className={icon}></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCustomCard;
