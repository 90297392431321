import React from 'react';
import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const AttendanceView = ({ changeHandler, fetchAttendance, programData,
  program_id, attendance_date, errorMessage }) => {
  return (
    <div className="row">
      <div className="col-md-6 pb-mb-2">
        <Input
          type="date"
          name="attendance_date"
          value={attendance_date}
          onChangeHandler={changeHandler}
          required={true}
          error={errorMessage}
        />
      </div>
      <div className="col-md-5 pb-mb-2">
        <Select
          name="program_id"
          placeHolder={'Select Class'}
          value={program_id}
          onChangeHandler={changeHandler}
          options={programData}
        />
      </div>
      <div className="col-md-1">
        <Button
          variant="primary"
          type="button"
          onClick={fetchAttendance}>
          View
        </Button>
      </div>
    </div>
  );
};

export default AttendanceView;
