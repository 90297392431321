import React from "react";
import { faClone, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Switch from "../../components/Switch/Switch";

export const tableConstants = (examDelete, selectDateChangeHandler, selectAllExamDateChangeHandler, exam_ids, eventHideAndShowHandler, permissions, page = 1) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{50 * (page - 1) + count++}</span>;
      },
    },
    {
      title: 'Exam Header',
      render: rowData => {
        return <span>{rowData.name && rowData.name.name}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
          {rowData.term === "UT-1" ? "UT-I" : null}
          {rowData.term === "UT-2" ? "UT-II" : null}
          {rowData.term === "UT-3" ? "UT-III" : null}
          {rowData.term === "PT-1" ? "PT-I" : null}
          {rowData.term === "PT-2" ? "PT-II" : null}
          {rowData.term === "PT-3" ? "PT-III" : null}
          {rowData.term === "1" ? "Term-I" : null}
          {rowData.term === "2" ? "Term-II" : null}
          {rowData.term === "3" ? "Common Pre-Board" : null}
          {rowData.term === "4" ? "Pre-Board" : null}
          {rowData.term === "5" ? "Other" : null}
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },

    {
      title: 'Subject',
      render: rowData => {
        return <span>{rowData.subject.name}</span>;
      },
    },

    {
      title: 'Evaluation',
      render: rowData => {
        return <span>{rowData.evaluation_type.name}</span>;
      },
    },

    {
      title: 'Start',
      render: rowData => {
        return <span>{rowData.start}</span>;
      },
    },
    {
      title: 'End',
      render: rowData => {
        return <span>{rowData.end}</span>;
      },
    },
    {
      title: 'Student Graded',
      render: rowData => {
        return <span>{rowData.total_mark_participant}/
          {rowData.total_participant}</span>;
      },
    },
    {
      title: 'Max Score',
      render: rowData => {
        return <span>{rowData.max_score}</span>;
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return <React.Fragment>
          {rowData.total_mark_participant == 0 ? (
            <span style={{ color: "red" }}> Not Graded</span>
          ) : (rowData.total_mark_participant > 0 && rowData.total_mark_participant != rowData.total_participant) ? (
            <span style={{ color: 'orange' }} >In Progress</span>
          ) : (
            <span style={{ color: 'green' }}>Graded</span>
          )}
        </React.Fragment>;
      },
    },
  ];

  if (permissions && permissions.includes("exam.hide.show")) {
    let rowData = {
      title: 'Hide/Show',
      render: rowData => {
        return <span>
          {
            permissions.includes("exam.hide.show") &&
            <Switch
              label=""
              name="is_active"
              checked={rowData.is_active}
              id={rowData.id}
              onChange={(e) => eventHideAndShowHandler(e, rowData)}
            />
          }
        </span>;
      },
    };
    formatedData.push(rowData);
  }

  if (permissions && (permissions.includes("exams.update") || permissions.includes("exams.create"))) {
    formatedData.push({
      title: () => <Switch
        label="Select all"
        name="end"
        // checked={exam_ids.length === rowData.length}
        id={"select-all"}
        onChange={(e) => selectAllExamDateChangeHandler(e)}
      />,
      render: rowData => {
        return <span>
          <Switch
            label=""
            name="end"
            checked={exam_ids.toString().includes(rowData.id)}
            id={"end-date" + rowData.id}
            onChange={(e) => selectDateChangeHandler(e, rowData)}
          />
        </span>;
      },
    });
  }

  if (permissions && (permissions.includes("exams.update") || permissions.includes("exams.delete"))) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <span>
            {permissions.includes("exams.update") &&
              <Link to={"/exam/update/" + rowData.id}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>}
            &nbsp;&nbsp;
            {permissions.includes("exams.delete") &&
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (window.confirm("Are you sure you wish to delete this rowData?"))
                    examDelete(rowData.id);
                }}
                icon={faTrash} color="red" />}
            &nbsp;&nbsp;
            {permissions.includes("exams.update") &&
              <Link to={"/exam/clone/create/" + rowData.id}>
                <FontAwesomeIcon icon={faClone} />
              </Link>}
          </span>
        );
      },
    });
  }
  return formatedData;
};
