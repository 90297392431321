import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getSyllabusById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.SYLLABUS.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getSyllabuss = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.SYLLABUS.GET, {
      params: {
        session_id: session_id,
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createSyllabus = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.SYLLABUS.CREATE, data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateSyllabus = async (id, data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.SYLLABUS.UPDATE}/${id}`, data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteSyllabus = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.SYLLABUS.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
