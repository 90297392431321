import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions, newsLetterDelete) => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'title',
      render: rowData => {
        return <span>{rowData.title}</span>;
      },
    },
    {
      title: 'Session',
      render: rowData => {
        return <span>{rowData.session}</span>;
      },
    },
    {
      title: 'Issue',
      render: rowData => {
        return <span>{rowData.issue}</span>;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          {permissions.includes('news_letter.update') &&
            <Link to={`/newsletter/update/${rowData.id}`}>
              <FontAwesomeIcon
                icon={faEdit}
                color="#321fdb"
                style={{
                  marginRight: "10px",
                  cursor: "pointer"
                }}
              />
            </Link>
          }
          &nbsp;
          {permissions.includes("news_letter.delete") &&
            <span
              className="cursor-pointer"
              onClick={() => {
                if (window.confirm(`Are you sure you wish to delete this item?`))
                  newsLetterDelete(rowData.id)
              }}>
              <i className="fa fa-trash text-danger"></i>
            </span>
          }
        </span>;
      },
    },
  ];
  return formatedData;
};
