import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions, syllabusDelete) => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Subject',
      render: rowData => {
        return <span>{rowData.subject.name}</span>;
      },
    },
    {
      title: 'class',
      render: rowData => {
        return <div>{rowData.syllabus_programs.map((item, idx) => <span key={idx}>{item.program.name}, </span>)}</div>;
      },
    },
    {
      title: 'Syllabus Description',
      render: rowData => {
        return <div dangerouslySetInnerHTML={{ __html: rowData.syllabus_desc }} />;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          {permissions.includes("syllabus.delete") &&
            <Link to={`/syllabus/update/${rowData.id}`}>
              <FontAwesomeIcon
                icon={faEdit}
                color="#321fdb"
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
            </Link>
          }
          &nbsp;
          {permissions.includes("syllabus.delete") &&
            <span
              className="cursor-pointer"
              onClick={() => {
                if (window.confirm(`Are you sure you wish to delete this item?`))
                  syllabusDelete(rowData.id)
              }}>
              <i className="fa fa-trash text-danger"></i>
            </span>
          }
        </span>;
      },
    },
  ];
  return formatedData;
};
