import React, { useState, useEffect } from 'react';
import Card from '../../../components/Card/Card';
import Loader from '../../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import LeaveForm from './LeaveForm';
import { getClassWiseStudentList } from '../../../services/StudentService';
import { getLeaveById, updateLeave, createLeave } from '../../../services/AppManagement/LeaveService';
import { getMappedPrograms } from '../../../services/AppManagement/AttendanceService';

const LeaveManage = () => {
  const [program_id, setProgramID] = useState('');
  const [student_id, setStudentID] = useState('');
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [leave_docs, setLeaveDocs] = useState('');
  const [apply_reason, setLeaveReason] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [leaveApprovalPrograms, setLeaveApprovalPrograms] = useState([]);
  const session_id = localStorage.getItem('session_id');
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramID(value);
      getStudentList(value);
    }
    else if (name === 'student_id') {
      setStudentID(value);
    }
    else if (name === 'from_date') {
      setFromDate(value);
    }
    else if (name === 'to_date') {
      setToDate(value);
    }
    else if (name === 'apply_reason') {
      setLeaveReason(value);
    }
  }

  const fileUploadHandler = (e) => {
    const file = e.target.files[0];
    setLeaveDocs(file);
  }
  const fetchMappedPrograms = async (id) => {
    try {
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let approverPrograms = response.data.data.leave_approver.map((item) => item.program);
        setLeaveApprovalPrograms(approverPrograms);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  };

  const getStudentList = async (programID) => {
    try {
      setIsLoader(true);
      const response = await getClassWiseStudentList(programID);
      if (response.status === 200) {
        let list = response.data.data.map((student) => { return { id: student.id, name: student.first_name + ' ' + student.last_name } });
        setStudentList(list);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  const getUniqueLeave = async (id) => {
    try {
      setIsLoader(true);
      const response = await getLeaveById(id);
      if (response.status === 200) {
        const data = response.data.data;
        getStudentList(data.program_id);
        setProgramID(data.program_id);
        setStudentID(data.student_id);
        setFromDate(data.from_date);
        setToDate(data.to_date);
        setLeaveReason(data.apply_reason);
        setLeaveDocs(data.docs_path);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem('user')).id;
    fetchMappedPrograms(user_id);
    if (id) {
      getUniqueLeave(id);
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('program_id', program_id);
    formData.append('student_id', student_id);
    formData.append('from_date', from_date);
    formData.append('to_date', to_date);
    formData.append('leave_docs', leave_docs);
    formData.append('apply_reason', apply_reason);
    formData.append('session_id', session_id);
    try {
      setIsLoader(true);
      const response = id
        ? await updateLeave(id, formData)
        : await createLeave(formData);

      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        navigate('/leave/list');
      }
    }
    catch (error) {
      return error;
    }
    finally {
      setIsLoader(false);
    }

  }

  const back = () => {
    navigate('/leave/list');
  }

  return (
    <Card componentName={id ? 'Update Leave' : 'Add Leave'} back={back}>
      {isLoader && <Loader />}
      <LeaveForm
        submitHandler={submitHandler}
        changeHandler={changeHandler}
        fileUploadHandler={fileUploadHandler}
        programData={leaveApprovalPrograms}
        program_id={program_id}
        studentList={studentList}
        student_id={student_id}
        from_date={from_date}
        to_date={to_date}
        apply_reason={apply_reason}
        leave_docs={leave_docs}
        id={id}
      />
    </Card>
  );
};
export default LeaveManage;
