import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { Link } from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import CardHeader from "../../components/Card/CardHeader";
import ListCard from "../../components/Card/ListCard";
import { deleteSubject } from "../../services/SubjectServices";

function SubjectList({ subjectData, loader, lastPage, fetchSubjects }) {
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  const org_id = localStorage.getItem("organization_id");

  useEffect(() => {
    if (!org_id) {
      enqueueSnackbar("Please select any Organization", { variant: "error" });
    } else {
      fetchSubjects({ selected: 0 });
    }
  }, [org_id, fetchSubjects]);

  const deleteHandler = async (id) => {
    try {
      const response = await deleteSubject(id);
      console.log(response);
      if (response.status === 200) {
        enqueueSnackbar("Subject deleted successfully", { variant: "success" });
        // Instead of calling componentDidMount, you can update Redux state here
        fetchSubjects({ selected: 0 });
      }
    } catch (error) {
      // Handle error
      return error;
    }
  };

  return (
    <div className="card">
      {(!subjectData || loader) ? (<Loader />) :
        (<div className="subject-list">
          <div className="animated">
            <CardHeader
              title="Scholastic Subjects"
              icon={<FaBookOpen />}
              permissions={permissions}
              button={permissions.includes("subjects.create") && (
                <Link className="btn btn-primary" to="/subject/create">Add</Link>
              )}
            />
            <div className="card-body row">
              {subjectData && subjectData.map((item, idx) => (
                <ListCard
                  key={idx}
                  colSize="col-md-6"
                  permissions={permissions}
                  updatePermission="subjects.update"
                  deletePermission="subjects.delete"
                  updateUrl={`/subject/update/${item.id}`}
                  icon="fa-solid fa-book-open"
                  item={item}
                  onDelete={deleteHandler}
                  description={
                    <div className="st-en-d">
                      <div className="small">
                        <span>Class</span>
                        <span>
                          {item && item.programs.map((program, index) => (
                            <React.Fragment key={index}>
                              {index === 0 ? (
                                <React.Fragment>
                                  {program.name}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  , {program.name}
                                </React.Fragment>
                              )}{" "}
                            </React.Fragment>
                          ))}
                        </span>
                      </div>
                    </div>
                  }
                />
              ))}
            </div>
            <div className="card-body evaluating-table">
              {subjectData.length ? (
                <Pagination
                  totalPage={lastPage}
                  handlePageChange={(i) => fetchSubjects(i)}
                />
              ) : null}
            </div>
          </div>
        </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    subjectData: state.subject.subjects.data,
    currentPage: state.subject.subjects.current_page,
    lastPage: state.subject.subjects.last_page,
    loader: state.subject.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubjects: (currentPage) => dispatch(fetchSubjects(currentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectList);
