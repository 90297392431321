import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getPrograms = async () => {
  try {
    const response = await axios.get(ENDPOINTS.PROGRAM.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getProgramById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.PROGRAM.GET_PROGRAM}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createProgram = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.PROGRAM.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateProgram = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.PROGRAM.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteProgram = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.PROGRAM.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClassInChargeClasses = async (session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.PROGRAM.GET_CLASS_IN_CHARGE_CLASSES,
      { headers: { 'session-id': session_id } });
    return response;
  } catch (error) {
    throw error;
  }
}
