import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ListCard = ({ colSize = "col-md-4", permissions, updatePermission, deletePermission,
  updateUrl, onDelete, icon, item, description = '', preTitle = '' }) => {

  return (
    <div className={`${colSize} mb-4`}>
      <div className="card-wrap border-gradient border-gradient-green only-top p-3">
        <div className="card-wrap-title-session">
          {preTitle} {item.name}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="school-icon">
            <i className={`${icon} pl-1`}></i>
          </div>
          <div className="">
            {permissions.includes(updatePermission) ? (
              <Link to={updateUrl}>
                <FontAwesomeIcon
                  icon={faEdit}
                  color="#321fdb"
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              </Link>
            ) : null}
            {permissions.includes(deletePermission) ? (
              <FontAwesomeIcon
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you wish to delete this item?"
                    )
                  )
                    onDelete(item.id);
                }}
                icon={faTrash}
                color="red"
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </div>
        </div>
        {description}
      </div>
    </div>
  );
};

export default ListCard;
