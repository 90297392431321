import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import Card from '../../components/Card/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { getScoreSchemeById, createScoreScheme, updateScoreScheme } from '../../services/ScoreSchemeService';
import ScoreSchemeForm from './ScoreSchemeForm';
import color_picker from '../ScoreSchemes/color_picker.png';
import Modal from '../../components/Modal/Modal';

const ScoreSchemesCreate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const isMounted = useRef(true); // Used to check if the component is mounted
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    options: [{ option_name: '', color: '', score: '' }],
  });
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [background, setBackground] = useState('#fff');

  const getUniqueScoreSchemes = async () => {
    try {
      setLoader(true);
      const response = await getScoreSchemeById(params.id);
      if (isMounted.current && response.status === 200) {
        const { name, type, ScoreOptions: options } = response.data;
        setFormData({ name, type, options });
      }
    } catch (error) {
      // Handle error here
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getUniqueScoreSchemes();
    }
  }, [params.id]);

  const handleChangeOption = (e, index) => {
    const { name, value } = e.target;
    const updatedOptions = [...formData.options];
    updatedOptions[index][name] = value;
    setFormData({ ...formData, options: updatedOptions });
  }

  const addNewRow = () => {
    setFormData({ ...formData, options: [...formData.options, { option_name: '', color: '', score: '' }] });
  }

  const clickOnDelete = (record) => {
    const updatedOptions = formData.options.filter(r => r !== record);
    setFormData({ ...formData, options: updatedOptions });
  }

  const handleShow = (event, index) => {
    setShowModal(true);
    setModalIndex(index);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let list = [];

    const updatedOptions = formData.options[0].option_name ? formData.options : list;
    const organization_id = localStorage.getItem('organization_id');
    const session_id = localStorage.getItem('session_id');

    if (params.id) {
      updatedOptions.forEach((item, idx) => {
        delete updatedOptions[idx]['id'];
        delete updatedOptions[idx]['score_scheme_id'];
        delete updatedOptions[idx]['created_at'];
        delete updatedOptions[idx]['updated_at'];
      });
    }

    const data = { name: formData.name, type: formData.type, organization_id, session_id, options: updatedOptions };
    try {
      setLoader(true);
      if (params.id) {
        const response = await updateScoreScheme(params.id, { data });
        if (isMounted.current && response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          navigate('/score/schemes/list');
        }
      } else {
        const response = await createScoreScheme({ data });
        if (isMounted.current && response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          navigate('/score/schemes/list');
        }
      }
    } catch (error) {
      // Handle error here
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  }

  const handleColorChangeComplete = (color, index) => {
    setBackground(color.hex);
    const updatedOptions = [...formData.options];
    updatedOptions[index].color = color.hex;
    setFormData({ ...formData, options: updatedOptions });
  };

  return (
    <Card componentName={params.id ? "Update Score Scheme" : "Add Score Scheme"}
      back={() => navigate('/score/schemes/list')}>
      <Modal isOpen={showModal} onClose={handleClose} >
        <div className='card-header'>
          <h5>Pick a Color</h5>
          <hr />
        </div>
        <div className="row cursor-pointer">
          <div className="col-md-3"></div>
          <div className="col-md-4">
            <SketchPicker
              color={background}
              onChangeComplete={(color) => handleColorChangeComplete(color, modalIndex)}
            />
          </div>
        </div>
        <hr />
        <div className="card-footer justify-content-end d-flex">
          <Button variant="primary" className='mt-3' onClick={handleClose}>
            Save
          </Button>
        </div>
      </Modal>
      <ScoreSchemeForm
        formData={formData}
        setFormData={setFormData}
        handleChangeOption={handleChangeOption}
        addNewRow={addNewRow}
        clickOnDelete={clickOnDelete}
        handleShow={handleShow}
        handleSubmit={handleSubmit}
        loader={loader}
        color_picker={color_picker}
      />
    </Card>
  );
};

export default ScoreSchemesCreate;
