import React from 'react';
import Switch from '../../../components/Switch/Switch';

const StudentSubjectEnrollment = (props) => {
  return (
    <div className="row col-md-12 mt-3">
      {props.program_id &&
        <Switch label="Select All"
          name="all"
          checked={props.subject_ids.length == props.subjectData.length}
          value="1"
          id='select-all'
          onChange={props.selectAllSubjectHandler}
        />
      }
      {props.subjectData && props.subjectData.map((subject, idx) =>
        <div key={idx} className='col-md-4 mt-2'>
          <Switch
            label={subject.name}
            name={subject.name}
            value={subject.id}
            id={subject.id}
            checked={props.subject_ids.includes(subject.id.toString())}
            onChange={(e) => props.subjectChangeHandler(e)}
          />
        </div>)
      }
    </div>
  );
};

export default StudentSubjectEnrollment;
