import React, { useState, useEffect } from "react";
import { FaCircle } from "react-icons/fa";
import { tableConstants } from "./CircularTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { deleteCircular, getCirculars } from "../../../services/AppManagement/CircularService";
import { enqueueSnackbar } from "notistack";

const CircularList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  async function fetchCircular({ selected = 0 }) {
    const session_id = localStorage.getItem('session_id');
    const page = selected + 1;
    setCurrentPage(selected);
    try {
      setIsLoader(true);
      const response = await getCirculars(session_id, page);
      setIsLoader(false);
      if (response.status === 200) {
        setData(response.data.data);
        setLastPage(response.data.last_page);
      }
    }
    catch (error) {
      setIsLoader(false);
      console.error("API Error:", error.response);
    }
  }

  useEffect(() => {
    fetchCircular({ selected: 0 });
  }, []);

  const circularDelete = async (id) => {
    try {
      const response = await deleteCircular(id);
      if (response.status === 200) {
        enqueueSnackbar("Circular Deleted Successfully", { variant: "success" });
        fetchCircular({ selected: 0 });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  }

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="Circular List"
          icon={<FaCircle />}
          button={permissions.includes('circular.create') &&
            <Link to="/circular/create"
              className="btn btn-primary">Add Circular</Link>}
        />
        <div className="card-body">
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <React.Fragment>
                  <Table
                    cols={tableConstants(permissions, circularDelete)}
                    data={data}
                    hover={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => fetchCircular(i)}
                  ></Pagination>
                </React.Fragment>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default CircularList;
