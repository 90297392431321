import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrograms } from '../../../redux/program/programActions';
import { Form, Button } from 'react-bootstrap';
import Select from '../../../components/Select/Select';
import Switch from '../../../components/Switch/Switch';

const StudentSubjectEnrollForm = props => {
  const dispatch = useDispatch();
  const programData = useSelector(state => state.program.programs.data);

  useEffect(() => {
    dispatch(fetchPrograms());
  }, []);

  return (
    <Form onSubmit={props.submitHandler} onChange={props.changeHandler}>
      <div className="row">
        <div className="col-md-6">
          <Select
            label={"Class"}
            name="program_id"
            value={props.program_id || ''}
            onChangeHandler={props.changeHandler}
            options={programData}
            required={true}
            placeHolder={'Select Class'}
          />
        </div>
        <div className="col-md-6">
          <Select
            label={"Student"}
            name="student_id"
            value={props.student_id || ''}
            onChangeHandler={props.studentChangeHandler}
            options={props.studentList}
            required={true}
            placeHolder={'Select Student'}
          />
        </div>
        <div className="row col-md-12 mt-3">
          {props.program_id &&
            <Switch label="Select All"
              name="all"
              checked={props.subject_ids.length == props.subjectData.length}
              value="1"
              id='select-all'
              onChange={props.selectAllSubjectHandler}
            />
          }
          {props.subjectData && props.subjectData.map((subject, idx) =>
            <div key={idx} className='col-md-3 mt-2'>
              <Switch
                label={subject.name}
                name={subject.name}
                value={subject.id}
                id={subject.id}
                checked={props.subject_ids.includes(subject.id.toString())}
                onChange={(e) => props.subjectChangeHandler(e)}
              />
            </div>)
          }
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end">
        <Button variant="success" className='mt-2'
          type="submit">Submit</Button>
      </div>
    </Form>
  );
};
export default StudentSubjectEnrollForm;

