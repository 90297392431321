import React from 'react';
import { Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';

const NewsLetterForm = ({ changeHandler, submitHandler, fileUploadHandler, title,
  id, issue, session, newsletter_file }) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-6">
          <Input
            label="Title"
            type="text"
            name="title"
            placeholder="Enter Title"
            value={title}
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Select
            label="Issue"
            name="issue"
            value={issue}
            onChangeHandler={changeHandler}
            required={true}
            placeHolder={'Select Issue'}
            options={[
              { id: 'issue-1', name: 'Issue-I' },
              { id: 'issue-2', name: 'Issue-II' },
              { id: 'issue-3', name: 'Issue-III' },
              { id: 'issue-4', name: 'Issue-IV' },
            ]}
          />
        </div>
        <div className="col-md-6">
          <Select
            label="Session"
            name="session"
            value={session}
            onChangeHandler={changeHandler}
            required={true}
            placeHolder={'Select Session'}
            options={[
              { id: '2024-2025', name: '2024-2025' },
            ]}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="NewsLetter File"
            type="file"
            name="newsletter_file"
            onChangeHandler={fileUploadHandler}
            required={id ? false : true}
          />
          {id && <Link to={newsletter_file} target="_blank">View File</Link>}
        </div>
      </div>
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-md-1">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default NewsLetterForm;
