import React, { useState, useEffect } from "react";
import { FaQuestion } from "react-icons/fa";
import { tableConstants } from "./FaqTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import Modal from "../../../components/Modal/Modal";
import { enqueueSnackbar } from "notistack";
import FaqForm from "./FaqForm";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { deleteFaq, getFaqs, createFaq, updateFaq } from "../../../services/AppManagement/FAQService";

const FaqList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitile] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState(null);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'title') {
      setTitile(value);
    }
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  async function fetchFaq({ selected = 0 }) {
    try {
      setIsLoader(true);
      setCurrentPage(selected);
      const page = selected + 1;
      const response = await getFaqs(page);
      // axios.get(`/faq?page=${page}`);
      setIsLoader(false);
      if (response.status === 200) {
        setData(response.data.data);
        setLastPage(response.data.last_page);
      }
    }
    catch (error) {
      setIsLoader(false);
      console.error("API Error:", error.response);
    }
  }

  useEffect(() => {
    fetchFaq({ selected: 0 });
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      title: title,
      description: description
    };

    try {
      setIsLoader(true);
      let response;
      if (id) {
        data.id = id;
        data.status = 1;
        response = await updateFaq(id, { data });
      } else {
        response = await createFaq({ data })
      }

      if (response.status === 200 || response.status === 201) {
        setTitile('');
        setDescription('');
        setId(null);
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        closeModal();
        fetchFaq({ selected: 0 });
      }
    } catch (error) {
      console.log("API Error:", error.response);
      enqueueSnackbar("Something went wrong", { variant: 'error' });
    }
    finally {
      setIsLoader(false);
    }
  };

  const showModel = (rowData) => {
    openModal(true);
    setId(rowData.id);
    setTitile(rowData.title);
    setDescription(rowData.description);
  }
  const FaqHandler = () => {
    openModal(true);
    setTitile('');
    setDescription('');
    setId(null);
  }

  const faqDelete = async (id) => {
    try {
      const response = await deleteFaq(id);
      // axios.delete(`/faq/${id}`);
      if (response.status === 200) {
        enqueueSnackbar("FAQ Deleted Successfully", { variant: "success" });
        fetchFaq({ selected: 0 });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  }

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="FAQ List"
          icon={<FaQuestion />}
          button={permissions.includes('faq.create') && <button className="btn btn-primary"
            onClick={FaqHandler}>Add FAQ</button>}
        />
        <div className="card-body">
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <h5>{id ? "Update FAQ" : "Add FAQ"}</h5>
            <FaqForm
              changeHandler={changeHandler}
              submitHandler={submitHandler}
              handleEditorChange={handleEditorChange}
              title={title}
              description={description}
            />
          </Modal>
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <React.Fragment>
                  <Table
                    cols={tableConstants(permissions, showModel, faqDelete)}
                    data={data}
                    hover={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => fetchFaq(i)}
                  ></Pagination>
                </React.Fragment>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default FaqList;
